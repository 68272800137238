import React from 'react';
import Header from '../sharedComponents/Header';
import projectsData from './Project';
import {  useParams } from 'react-router-dom';
import Footer from '../sharedComponents/footer';
import { Helmet } from 'react-helmet-async';
import NotFoundPage from '../subComponents/NotFoundPage';

const CaseStudyPages = () => {
    const { id } = useParams();
    const project = projectsData.find(
        (project) => project.id === id
    );

    if (!project) {
        return <NotFoundPage />;
    }

    return (
        <>
            <Helmet>
            <title>{project?.metaTitle}</title>
        <meta
          name="description"
          content={project?.metaDescription}
        />
                <link rel="canonical" href={project.canonicalTag} />
            </Helmet>
            <Header />
            <div className="bg-gray-100 w-full px-[7%] xl:px-[160px] ipad-pro:px-[100px]">
                <div className="flex flex-col md:flex-row items-center md:flex-row-reverse justify-between w-full ">
                    <div className="w-[100%] md:w-[40%]  mt-6  md:mt-6 md:mb-6 flex justify-center md:justify-end">
                        <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"
                            src={project.image}
                            alt={project.altText}
                            className={`h-[100%] object-cover ${project.id === 'paypal' ? 'md:w-[60%] w-[100%]' : 'md:w-[90%] w-[100%]'} `}
                        />
                    </div>
                    <div className="w-full md:w-1/2  flex mt-6 mb-6  flex-col items-center md:items-start">
                        <h2 className="text-5xl font-bold mb-4 relative inline-block text-center md:text-left">
                            {project.title}
                        </h2>
                        <p className="text-lg text-gray-600 leading-relaxed text-center md:text-left">
                            {project.description}
                        </p>
                    </div>


                </div>


            </div>

            <h3 className="text-4xl font-bold text-center mt-6 md:mt-24 xl:px-[160px] ipad-pro:px-[100px]">Challenges Faced</h3>
            <div className="flex justify-center   bg-white rounded-lg ">
                <div className="w-full p-5 pb-2 md:p-24 xl:px-[160px] ipad-pro:px-[100px]">
                    <ul className="grid grid-cols-1 items-center md:grid-cols-2 gap-6 md:gap-x-8 md:gap-y-6 text-gray-700">
                        {project.challenges.map((challenge, index) => (
                            <li
                                key={index}
                                className="flex flex-col p-6 bg-white rounded-lg shadow-2xl overflow-hidden content-center items-center text-center justify-center group z-10 hover:shadow-xl transition-shadow duration-300 h-[150px]"
                            >
                                <div className="flex items-center">
                                    <p className="ml-2 text-xl font-semibold">{challenge.title}</p>
                                    <p className="mt-2 text-gray-600 text-center">{challenge.description}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>


            <div className="flex xl:px-[160px] ipad-pro:px-[100px] mt-10 flex-col lg:flex-row items-center justify-center text-center lg:text-left mb-7 md:mb-12 p-6 bg-[#FAFAFA]">
                <div className="lg:w-1/2 mt-6 lg:mt-0 flex justify-center">
                    <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy" src="https://webkorps-app.s3.us-east-1.amazonaws.com/solution.jpg" alt="solution " className="rounded-lg shadow-md w-full lg:w-3/4 max-w-md" />
                </div>
                <div className="lg:w-1/2">
                    <h1 className="text-4xl font-bold mx-auto max-w-2xl mt-6">Solution:</h1>

                    <h2 className="text-2xl font-medium text-[#072125] mt-2 mx-auto max-w-2xl">Unlock the Power of Mobility</h2>

                    <div className="text-[#072125] mt-4 mx-auto max-w-2xl">
                        {project.solution && project.solution.split('. ').map((item, index) => {
                            if (item.includes(':')) {
                                const [heading, description] = item.split(':');
                                return (
                                    <p key={index} className="mt-2">
                                        <strong>{heading}:</strong> {description && description.trim() }
                                    </p>
                                );
                            } else {
                                return (
                                    <p key={index} className="mt-2">
                                        {item.trim() }
                                    </p>
                                );
                            }
                        })}
                    </div>
                </div>

            </div>

            <div className="bg-white p-8 pt-0 rounded-lg shadow-md">
                <div className="max-w-4xl mx-auto">
                    <h3 className="text-4xl font-bold text-center mb-6">Tech Stacks</h3>
                    <ul className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6 text-gray-700">
                        {project.techStacks.map((tech, index) => (
                            <li key={index} className="flex flex-col p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
                                <div className="flex items-center text-center justify-center">
                                    <p className="ml-2 text-xl font-semibold ">{tech}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default CaseStudyPages;
