import React from 'react';

const Card = ({ image, title, text, altText }) => {
  return (
    <div className="relative sm:w-[100%] md:w-[100%] xl:w-[100%] lg:h-[330px] h-[390px] xl:h-[390px] ipad-pro:h-[400px] border border-gray-300 rounded-lg overflow-hidden flex flex-col items-center justify-between p-4 shadow-lg"> {/* Added shadow-lg */}
      <div className="flex flex-col items-center justify-center text-center flex-grow bg-opacity-50 rounded-b-lg">
        <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"
          src={image}
          alt={altText}
          className="w-auto max-w-[45%] h-auto max-h-[70vh] object-cover mb-10"
          />        <h3 className="text-xl font-quicksand font-bold mb-2">{title}</h3>
        <p className="text-base">{text}</p>
      </div>
    </div>
  );
};
const CardContainer = () => {
  const cards = [
    { 
      image: "https://webkorps-app.s3.us-east-1.amazonaws.com/innovation.webp", 
      title: 'Innovation', 
      text: 'We encourage and empower our people to think outside the box and drive innovation in the tech industry.', 
      altText: 'innovation' 
    },
    { 
      image: "https://webkorps-app.s3.us-east-1.amazonaws.com/collaboration.webp", 
      title: 'Collaboration', 
      text: 'We encourage and empower our people to think outside the box and drive innovation in the tech industry.', 
      altText: 'collaboration' 
    },
    { 
      image: "https://webkorps-app.s3.us-east-1.amazonaws.com/accessibility.webp", 
      title: 'Accessibility', 
      text: 'We encourage and empower our people to think outside the box and drive innovation in the tech industry.', 
      altText: 'accessibility' 
    },
    { 
      image: "https://webkorps-app.s3.us-east-1.amazonaws.com/candour.webp", 
      title: 'Candour', 
      text: 'We encourage and empower our people to think outside the box and drive innovation in the tech industry.', 
      altText: 'candour' 
    },
  ];
  
  return (
    <div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-2  xl:grid-cols-4  ipad-pro:grid-cols-2 xl:gap-5  ipad-pro:gap-15  gap-10">
      {cards.map((card, index) => (
        <Card key={index} image={card.image} title={card.title} text={card.text} altText={card.altText} />
      ))}
    </div>
  );
};
export default CardContainer;