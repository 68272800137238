import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const services = [
    {
        name: "Application Development",
        image: "https://webkorps-app.s3.us-east-1.amazonaws.com/mobile-application-development.webp",
        subTitle: 'Your solution, delivered.',
        description: "We take an iterative approach to crafting digital experiences on the web and mobile that tackle your most complex business challenges.",
        alt: "mobile-application-development",
        path: "/mobile-app-development",
        offers: [
            { title: 'iOS App Development', details: 'We craft high-performance, user-friendly iOS apps tailored to your business needs. Our team ensures seamless functionality, sleek design, and superior user experiences.' },
            { title: 'UI/UX Design', details: 'Our expert designers craft intuitive, visually stunning interfaces, ensuring that your app not only looks great but also delivers an exceptional user experience.' },
            { title: 'Android App Development', details: 'We specialize in building custom Android apps that deliver smooth performance and robust features. Our solutions are designed to help your business thrive on the Android platform.' },
            { title: 'Custom Mobile Application', details: 'We develop tailor-made mobile applications that align perfectly with your unique business requirements, ensuring a personalized and scalable solution.' },
            { title: 'Cross-Platform Development', details: 'We create efficient cross-platform apps that work seamlessly on both iOS and Android, ensuring consistent performance and user experience across devices.' },
            { title: 'Mobile App Maintenance', details: 'Our dedicated support team provides continuous maintenance and updates, ensuring your app remains secure, up-to-date, and running smoothly over time.' },
        ]
    },
    {
        name: "Digital Transformation",
        image: "https://webkorps-app.s3.us-east-1.amazonaws.com/digital.webp",
        subTitle: 'Transforming your business, digitally.',
        description: "We take an iterative approach to crafting digital experiences on the web and mobile that tackle your most complex business challenges.",
        alt: "Digital Transformation",
        path: "/digital-transformation"
    },
    {
        name: "Cloud Solutions",
        image: "https://webkorps-app.s3.us-east-1.amazonaws.com/cloudSolutions.webp",
        subTitle: 'Harness the power of the cloud.',
        description: "Our cloud solutions enable you to scale seamlessly, providing flexibility and innovation while reducing operational overhead.",
        alt: "cloud-solutions",
        path: "/cloud-services"
    },
    {
        name: "Manage IT Solutions",
        image: "https://webkorps-app.s3.us-east-1.amazonaws.com/itSolutions.webp",
        subTitle: 'Effortless IT management, simplified.',
        description: "We streamline IT management with tailored solutions, allowing you to focus on growth while we handle the complexities.",
        alt: "Manage IT Solutions",
        path: "/IT_services"
    },
    {
        name: "MVP to Product",
        image: "https://webkorps-app.s3.us-east-1.amazonaws.com/MVP.webp",
        subTitle: 'From MVP to product.',
        description: "We turn your MVP into a fully-fledged product, using a step-by-step process to ensure scalability, performance, and success.",
        alt: "MVP to Product",
        path: "/our_services"
    },
    {
        name: "Top Integrations",
        image: "https://webkorps-app.s3.us-east-1.amazonaws.com/topIntregations.webp",
        subTitle: 'Seamless integrations, optimized.',
        description: "Our top integrations connect all your systems, enhancing productivity and ensuring optimal performance across platforms.",
        alt: "Top Integrations",
        path: "/top_services"
    },
];


const ServicesListContainer = () => {
    const [selectedService, setSelectedService] = useState(services[0]);
    const navigate = useNavigate();

    const handleServiceClick = (service) => {
        setSelectedService(service);
    };

    return (
        <div className="content px-[7%] xl:px-[160px] ipad-pro:px-[100px] w-full  mt-14 md:mt-14">
            <p className="font-quicksand sm:text-center md:text-left lg:text-left text-center font-bold text-[32px] md:text-[36px] lg:text-[48px] text-left">
                Our Services
            </p>

            <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row justify-center mt-6 md:mt-12 xl:mt-12 ipad-pro:mt-5">
                <div className="w-full sm:w-full md:w-full xl:w-[55%] ipad-pro:w-[70%]  sm:text-center md:text-left lg:text-left text-center ">
                    <ul className="font-quicksand font-bold text-[23px] leading-[36px]">
                        {services.map((service, index) => (
                            <li
                                key={index}
                                className={`mb-6 md:mb-12  xl:mb-10 ipad-pro:mb-8 pl-2 cursor-pointer ${selectedService.name === service.name
                                    ? "text-[#1887C9] border-l-2 border-[#1887C9] "
                                    : "text-black"
                                    }`}
                                onClick={() => handleServiceClick(service)}
                            >
                                {service.name}
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="md:w-[100%] w-full flex flex-col items-center md:items-start ">
                    <div className="relative w-full group">
                        <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"

                            src={selectedService.image}
                            alt={selectedService.alt}
                            className="max-w-full w-[736px] h-[208px] md:h-[160px] xl:h-[288px]  xl:w-[736px] xxl:w-[100%] ipad-pro:w-[750px] ipad-pro:h-[200px]  rounded-lg grayscale-image transition-transform duration-300  group-hover:grayscale-0"
                            onClick={() => { navigate(selectedService?.path, { offers: selectedService?.offers }) }}
                        />
                        <div
                            className={`absolute ${selectedService.name === "Manage IT Solutions" ? "top-[80%] ipad-pro:top-[70%] xl:top-[75%] md:top-[80%] left-[25%] xl:left-[25%] ipad-pro:left-[10%] md:left-[25%] " : selectedService.name === "MVP to Product" ?"top-0 left-0": "top-[25%] left-10"
                                } h-full w-full flex items-left justify-left `}
                            onClick={() => {
                                navigate(selectedService?.path);
                            }}
                        >
                            <div
                                className={`text-white text-[20px] md:text-[18px] lg:text-[40px] font-bold cursor-pointer ${selectedService.name === "MVP to Product" ? "bg-gradient-to-r from-black  to-transparent pt-[60px] pl-[20px] rounded-[25px]" : ""}`}
                                onClick={() => {
                                    navigate(selectedService?.path);
                                }}
                            >
                                {selectedService.name === "Manage IT Solutions" ? (
                                    <span>
                                        {selectedService.name}
                                        <br />
                                    </span>
                                ) : (
                                    selectedService.name.split(" ").map((word, index) => (
                                        <span key={index}>
                                            {word}
                                            <br />
                                        </span>
                                    ))
                                )}
                            </div>
                        </div>

                    </div>


                    <div className="mt-6">
                        {/* Title */}
                        <p className="text-2xl font-bold text-center  md:text-left lg:text-left text-black md:mt-0 ">
                            {selectedService?.subTitle && (
                                <span>
                                    {selectedService.subTitle.includes(",") ? (
                                        <>
                                            {selectedService.subTitle.split(",")[0]},
                                            <span className="text-[#007bff]">
                                                {selectedService.subTitle.split(",")[1]}
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            {selectedService.subTitle
                                                .split(" ")
                                                .slice(0, -1)
                                                .join(" ")}{" "}
                                            <span className="text-[#007bff]">
                                                {selectedService.subTitle.split(" ").slice(-1)}
                                            </span>
                                        </>
                                    )}
                                </span>
                            )}
                        </p>

                        {/* Description */}
                        <p className="text-lg text-center  md:text-left lg:text-left text-gray-700 mt-2">
                            {selectedService?.description}
                        </p>

                        {/* Blue Gradient Bar */}
                        <div className="mt-4 w-full h-2 custom-gradient-bar rounded-lg"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicesListContainer;
