import React, { useEffect, useRef, useState } from 'react';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css'; 
import Slider from 'react-slick';

const Card = ({ image ,alt}) => {
  return (
    <div className="md:w-[96%]  xl:w-[256px] ipad-pro:w-[96%] ipad-pro:h-[450px] w-[96%] ipad-sum:h-[370px]  h-[383px] md:h-[418px] xl:h-[312px]  rounded-[16px] overflow-hidden mx-auto md:mx-1">
      <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy" src={image} alt={alt} className="w-full  h-full object-cover" />
    </div>
  );
};

const AchievementCardContainer = () => {
  const cards = [
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/iso-certificate-27001.webp", altText: "iso-certificate-27001" },
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/achivement-startup-india.webp", altText: "achivement-startup-india" },
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/achivement-appfutura.webp", altText: "achivement-appfutura" },
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/achivement-wadline.webp", altText: "achivement-wadline" },
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/achivement-goodfirms.webp", altText: "achivement-goodfirms" },
    { image: "https://webkorps-app.s3.us-east-1.amazonaws.com/achivement-cmmi-level-three.webp", altText: "achivement-cmmi-level-three" },
  ];
  

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: "0px", // Ensures there’s no extra space on the sides
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 820, // For iPad Air (820px width in portrait mode)
        settings: {
          slidesToShow: 2, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 868,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ],
  };

  const webSliderRef = useRef(null);
  const [webDirection, setWebDirection] = useState('right');

  useEffect(() => {
    const autoPlayWeb = setInterval(() => {
      if (webSliderRef.current) {
        webDirection === 'right' ? webSliderRef.current.slickNext() : webSliderRef.current.slickPrev();
      }
    }, 5000);

    return () => clearInterval(autoPlayWeb);
  }, [webDirection]);

  return (
    <div className="pb-10 relative">
 <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"
  src="https://webkorps-app.s3.us-east-1.amazonaws.com/circleleft.svg"
  alt="circleleft"
   className="absolute top-0 left-1/2 transform -translate-x-[65%] translate-y-[50%] md:translate-y-[43%] ipad-pro:translate-y-[40%] ipad-pro:w-[100%]  w-[90%] xl:translate-y-[16%] xl:-translate-x-[127%] max-w-[800px] h-[70%] xl:h-[100%] xl:w-[50%]  pointer-events-none z-0"
  />

  {/* Slider */}
  <Slider
    {...settings}
    ref={webSliderRef}
    className="mt-5 max-w-full relative z-10"
    beforeChange={(oldIndex, newIndex) => {
      if (newIndex === 0 && webDirection === 'left') {
        setWebDirection('right'); 
      }
    }}
  >
    {cards.map((image, index) => (
      <div
        key={index}
        className="flex justify-center pl-0 ipad-pro:pl-0 xl:pl-0 md:pl-0 xxl:pr-[4%] md:justify-start"
      >
        <Card image={image?.image} alt={image?.altText} />
      </div>
    ))}
  </Slider>
</div>

  );
};

export default AchievementCardContainer;
