import React, { useEffect, useState } from "react";
import Header from "../sharedComponents/Header";
import { Link } from "react-router-dom";
import Footer from "../sharedComponents/footer";
import { Helmet } from 'react-helmet-async';

export default function JoinUs() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleSelect = (index) => {
    setSelectedIndex(index);
  };
  const [jobOpenings, setJobOpenings] = useState([]);

  useEffect(() => {
    fetch("https://be.webkorps.com:4202/v1/career_jobs")
      .then((response) => response.json())
      .then((data) => setJobOpenings(data))
      .catch((error) => console.error("Error:", error));
  }, []);

  const departments = [
    "hr_and_recruitment",
    "support",
    "sales_and_marketing",
    "engineering",
  ];

  // Filter job openings based on the selected department
  const filteredJobs = jobOpenings.filter(
    (job) => job.job_title === departments[selectedIndex]
  );
  const jobCounts = departments.map((dept) =>
    jobOpenings.filter((job) => job.job_title === dept).length
  );
  const cardData = [
    {
      title: "Team Work",
      description:
        "Go to this step-by-step guideline process on how to certify for your weekly benefits:",
      linkText: "See our guideline",
      linkHref: "#",
      image: "https://webkorps-app.s3.us-east-1.amazonaws.com/team-work.webp", 
      altText: "team-work",
      backgroundColor: "#E7F2FF",
    },
    {
      title: "Secured Future",
      description:
        "Learn how to effectively manage your projects with our comprehensive guide:",
      linkText: "Read more",
      linkHref: "#",
      image: "https://webkorps-app.s3.us-east-1.amazonaws.com/secured-future.webp",
      backgroundColor: "#F1F7E8",
      altText: "secured-future",
    },
    {
      title: "Learning Opportunity",
      description:
        "Explore the opportunities and tools available for advancing your career:",
      linkText: "Discover more",
      linkHref: "#",
      image: "https://webkorps-app.s3.us-east-1.amazonaws.com/learning-opportunity.webp", 
      backgroundColor: "#EFF2F5",
      altText: "learning-opportunity",
    },
    {
      title: "Upgrade Skills",
      description:
        "Enhance your Upgrade skills with our expert tips and strategies:",
      linkText: "Get the tips",
      linkHref: "#",
      image: "https://webkorps-app.s3.us-east-1.amazonaws.com/data-analytics.webp", 
      backgroundColor: "#FFEEED",
      altText: "data-analytics",
    },
  ];
  const [isModalOpen, setModalOpen] = useState(false);

  const handleApplyNow = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    phone_number: '',
    reference_name: '',
    gender: '',
    resume: '',
    agree: false
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showThankYouPopup, setShowThankYouPopup] = useState(false);
  const validateForm = () => {
    const newErrors = {};
    if (!formData.full_name.trim()) {
      newErrors.full_name = "Full Name is required.";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid.";
    }
    if (!formData.phone_number.trim()) {
      newErrors.phone_number = "Phone Number is required.";
    } else if (!/^\d{10}$/.test(formData.phone_number)) {
      newErrors.phone_number = "Phone Number must be 10 digits.";
    }
    if (!formData.gender.trim()) {
      newErrors.gender = "Gender is required.";
    }
    if (!formData.resume) {
      newErrors.resume = "Resume is required.";
    }
    if (!formData.agree) {
      newErrors.agree = "You must accept the Terms and Conditions.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'resume') {
      setFormData(prevState => ({
        ...prevState,
        [name]: files[0],
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }

    if (name === 'email' && value && !/\S+@\S+\.\S+/.test(value)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        email: "Email is invalid.",
      }));
    } else {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: '',
      }));
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const formDataObj = new FormData();
    Object.keys(formData).forEach(key => {
      formDataObj.append(key, formData[key]);
    });
    setLoading(true)
    try {
      const url = 'https://be.webkorps.com:4202/v1/job_applies/send_notifications';
      const response = await fetch(url, {
        method: 'POST',
        body: formDataObj,
      });
      if (response.ok) {
        setLoading(false)
        console.log('Form submitted successfully!');

        closeModal()
        setFormData({
          full_name: '',
          email: '',
          phone_number: '',
          reference_name: '',
          gender: '',
          resume: ''
        });
        setShowThankYouPopup(true); // Show Thank You popup
      } else {
        setLoading(false)
        alert('Form submission failed!', response.statusText);
      }
    } catch (error) {
      setLoading(false)
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (showThankYouPopup) {
      const timer = setTimeout(() => {
        setShowThankYouPopup(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [showThankYouPopup]);
  return (
    <div className="overflow-hidden " >
      <Helmet>
        <title>Job Openings and Career Opportunities at Webkorps</title>
        <meta
          name="description"
          content="Explore exciting career opportunities at Webkorps! Check out our latest job openings and join a dynamic team dedicated to innovation, growth, and success in the IT industry."
        />
        <meta property="og:url" content="https://www.webkorps.com/join-us" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Job Openings and Career Opportunities at Webkorps" />
        <meta property="og:description" content="Explore exciting career opportunities at Webkorps! Check out our latest job openings and join a dynamic team dedicated to innovation, growth, and success in the IT industry." />
        <meta property="og:image" content="https://webkorps-app.s3.us-east-1.amazonaws.com/Job+Openings+and+Career+Opportunities+at+Webkorps.webp" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="webkorps.com" />
        <meta property="twitter:url" content="https://www.webkorps.com/join-us" />
        <meta name="twitter:title" content="Job Openings and Career Opportunities at Webkorps" />
        <meta name="twitter:description" content="Explore exciting career opportunities at Webkorps! Check out our latest job openings and join a dynamic team dedicated to innovation, growth, and success in the IT industry." />
        <meta name="twitter:image" content="https://webkorps-app.s3.us-east-1.amazonaws.com/Job+Openings+and+Career+Opportunities+at+Webkorps.webp" />
        <link rel="canonical" href="https://www.webkorps.com/join-us" />
      </Helmet>
      <Header />
      <div className="flex justify-center overflow-hidden  md:mt-4 relative w-full h-[20rem] md:h-[25rem]">
        <img width="800" height="600" sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"
          src="https://webkorps-app.s3.us-east-1.amazonaws.com/join-us.webp"
          alt="join-us"
          className="w-full h-full object-fill opacity-70"
        />
        <div
          className="absolute inset-0 flex items-center justify-center flex-col bg-gradient-to-t from-[#001833] to-[#001833] opacity-[0.85]"
        >

          <h1 className="text-4xl md:text-5xl text-white font-bold">Join Us</h1>
          <h2 className="text-center text-white mt-4 px-4 md:px-0">
            Step into a world where innovation meets excellence. Be part of <br /> a team that’s shaping   the future of technology and redefining <br /> possibilities. Together, let's create, inspire, and achieve greatness!
          </h2>
          <button className="text-white text-base tracking-wide font-bold bg-[#1887C9] px-4 py-2 rounded-md mt-4" onClick={() => document.getElementById('career-openings').scrollIntoView({ behavior: 'smooth' })}
            d          >
            See Open Position
          </button>
        </div>
      </div>
      <div className=" px-[7%] xl:px-[160px] ipad-pro:px-[100px] w-full  overflow-hidden text-center md:text-left mb-3.5   mt-6 md:mt-16">
        <div className="grid grid-cols-1 gap-5 xl:gap-[20%] ipad-pro:gap-[10%] md:grid-cols-1   xl:grid-cols-[40%_60%] ipad-pro:grid-cols-[40%_60%] h-full xl:h-[450px] ipad-pro:h-auto md:h-auto ">
          <div>
            <h4 className="basic-black font-bold text-[23px] md:text[23px]">Benefits</h4>
            <h1 className="font-quicksand text-h3Custom leading-h3Custom font-bold md:text-left ">
              Why You Should Join Our <br />
              Awesome Team
            </h1>
            <p className="font-quicksand text-smCustom text-center md:text-left font-light leading-4.5  mt-3 mb-3 ">
              We want to feel at home when you are working at Webkorps & for that we have curated a great set of benefits for you. It all starts with the free lunch!
            </p>
          </div>
          <div className="w-[100%] xl:w-[70%] ipad-pro:w-[85%] grid gap-2 grid-cols-1 md:grid-cols-2 ipad-sum:justify-items-center">
            {cardData.map((card, index) => (
              <div key={index} className="max-w-sm flex flex-col mb-3 justify-center md:justify-start md:items-start  items-center md:flex-col ">
                <div
                  className="w-[74px] h-[76px] mb-0 md:mb-3  flex items-center justify-center"
                  style={{
                    backgroundColor: card.backgroundColor,
                  }}
                >
                  <img width="800" height="600" sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"

                    src={card.image}
                    alt={card.altText}
                    className="w-12 h-12"
                  />
                </div>
                <div className="mt-0 md:mt-8">
                  <h5 className="mb-2 font-bold tracking-tight basic-black text-[23px] leading-[32.2px]">
                    {card.title}
                  </h5>
                  <p className="mb-3 font-normal text-gray-500 dark:text-gray-400 basic-black text-sm">
                    {card.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div id="career-openings"
        className=" overflow-hidden mt-[50px]  section-bg-color pt-7 pb-3">
        <div className="  text-center text-dark h-auto pb-[83px] pt-[72px] ">
          <h1 className=" basic-black font-bold text-[23px] leading-[32.2px]">
            Come join us
          </h1>
          <h4 className="basic-black font-bold text-3xl mt-2">
            Career Openings
          </h4>
          <p className="text-base mt-4 font-normal ">
            We’re always looking for creative, talented self-starters to join the Webkorps
            family.
          </p>
          <p>Check out our open roles below and fill out an application.</p>
          <div className="mt-[24px] w-full">
            {jobOpenings.length !== 0 ? (
              <div className="flex flex-col xl:flex-row px-0 ipad-pro:flex-row md:flex-row ipad-pro:px-[5%] md:px-[5%]  xl:px-[250px] ipad-pro:gap-[53px] gap-0 md:gap-[35px] xl:gap-[53px]">
                <div className="block md:relative">
                  <ul className="basic-black ipad-pro:text-left md:text-left text-center xl:text-left text-[13px] leading-[18.2px] font-bold md:top-0 md:right-10 justify-center md:justify-items-start font-semibold line-height-1 space-y-2">
                    {departments.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => handleSelect(index)}
                        className={`cursor-pointer pb-5 font-bold uppercase rounded ${selectedIndex === index ? "text-[#D2232A]" : "text-black"
                          }`}
                      >
                        {item
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (char) => char.toUpperCase())}{" "}
                        {jobCounts[index] > 0 && (
                          <span className="ml-2 text-gray-500">
                            ({jobCounts[index]})
                          </span>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>

                <div
                  className={` max-h-[540px] overflow-y-auto ${filteredJobs.length === 0 ? 'content-center   w-[60%]' : ''}`}
                >
                  {filteredJobs.length > 0 ? (
                    filteredJobs.map((job) => (
                      <Link to={`/join-us/${job.id}`} key={job.id}>
                        <div className="block mb-[32px] mx-[20px] xl:mx-0 ipad-pro:mx-0 md:mx-0 bg-white xl:h-[75px] ipad-pro:h-[75px] md:h-[75px] h-[100%] pt-[10px] pb-[10px] xl:pt-0 xl:pb-0 ipad-pro:pb-0 ipad-pro:pt-0 md:pt-0 md:pb-0   ipad-pro:w-[720px] md:w-[505px] xl:w-[715px] xxl:w-[940px] items-center align-center justify-center content-center drop-shadow cursor-pointer">
                          <div className="flex xl:flex-row ipad-pro:flex-row md:flex-row flex-col items-center  px-[20px]   text-center font-bold justify-between  ">
                            <div className="md:col-span-2 w-[200px]">
                              <p className="text-[19px] leading-[26px] text-center">
                                {job.designation}
                              </p>
                            </div>
                            <div className="md:col-span-2 w-[200px]">
                              <p className="text-[15px] md:text-[11px]  text-center text-[#D4D4D4]">
                                Experience
                              </p>
                              <p className="text-[13px] md:text-[19px] leading-[26px]">
                                {job.experience}
                              </p>
                            </div>
                            <div className="md:col-span-2 w-[200px]">
                              <p className="text-[15px] md:text-[11px]  text-centert  text-[#D4D4D4]">
                                Deadline
                              </p>
                              <p className="text-[13px] md:text-[19px] leading-[26px]">
                                {job.deadline}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))
                  ) : (
                    <div className="text-center w-full">NO JOBS AVAILABLE</div>
                  )}
                </div>
              </div>
            ) : (
              <div>NO DATA AVAILABLE</div>
            )}
          </div>

        </div>
      </div>
      <div
        className=" overflow-hidden   secondary-bg-color pt-7 pb-3">
        <div className="  text-center text-dark h-auto pb-[83px] pt-[72px] ">
          <h2 className=" basic-black font-bold text-[30px] leading-[35.2px]">
            Didn’t Find the Right Role? <br />Share Your Resume With Us!
          </h2>

          <p className="text-base mt-4 font-normal ">
            We’re always looking for talented individuals like you. Submit your resume,<br /> and we’ll contact you when a suitable opportunity arises!
          </p>
          <div>
            <button className="text-white text-base tracking-wide font-bold bg-[#1887C9] px-4 py-2 rounded-md mt-4" onClick={handleApplyNow}   >
              Share Your Resume
            </button>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-[95%] md:w-[50%] ">
            <button
              className="text-gray-500 hover:text-black text-lg font-bold float-right"
              onClick={closeModal}
            >
              &times;
            </button>
            <h2 className="text-xl font-bold mb-4 text-center">Job Application</h2>
            <form onSubmit={handleSubmit}
              encType="multipart/form-data">
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div>
                  <label className="block text-[#000000] text-[14px] leading-[16px] font-chivo  font-medium mb-2" htmlFor="fullname">
                    Name
                  </label>
                  <input
                    className={`shadow h-[48px] text-[14px] leading-[16px] appearance-none border-[1px] border-b-4 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none ${errors.full_name ? 'border-red-500' : 'border-black'
                      }`}
                    id="fullname"
                    type="text"
                    placeholder="Enter your full name"
                    name="full_name"
                    value={formData.full_name}
                    onChange={handleChange}
                  />
                  {errors.full_name && <p className="text-red-500 text-xs mt-2">{errors.full_name}</p>}
                </div>
                <div>
                  <label className="block text-[#000000] text-[14px] leading-[16px] font-chivo  font-medium mb-2" htmlFor="email">
                    Email
                  </label>
                  <input
                    className={`shadow h-[48px] text-[14px] appearance-none border-[1px] border-b-4  rounded w-full py-2 px-3 text-gray-700 focus:outline-none ${errors.email ? "border-red-500" : "border-black"
                      }`}
                    id="email"
                    type="email"
                    placeholder="Enter your email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <p className="text-red-500 text-xs mt-2">{errors.email}</p>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div className="mb-4 mt-[30px]">
                  <label className="block text-[#000000] text-[14px] leading-[16px] font-chivo  font-medium mb-2" htmlFor="phone">
                    Phone Number
                  </label>
                  <div className="flex">
                    <input
                      className={`shadow h-[48px] text-[14px] appearance-none border-[1px] border-b-4  rounded w-full py-2 px-3 text-gray-700 focus:outline-none ${errors.phone_number ? "border-red-500" : "border-black"
                        }`}
                      id="phone_number"
                      type="text"
                      placeholder="Enter your phone number"
                      name="phone_number"
                      value={formData.phone_number}
                      onChange={handleChange}
                    />

                    {/* <button className="ml-2 px-4 py-2 border-2 border-[rgba(74,61,229,0.25)] text-[rgba(74,61,229,0.25)] font-bold rounded   focus:outline-none focus:shadow-outline">
                    Verify
                  </button> */}
                  </div>
                  {errors.phone_number && (
                    <p className="text-red-500 text-xs mt-2">{errors.phone_number}</p>
                  )}
                </div>
                <div className="mb-4 mt-[30px]">
                  <label className="block text-[#000000]  text-[14px] leading-[16px] font-chivo  font-medium mb-2" htmlFor="reference">
                    Reference Name
                  </label>
                  <input
                    className={`shadow h-[48px] text-sm appearance-none border-[1px] border-b-4  rounded w-full py-2 px-3 text-gray-700 focus:outline-none ${errors.reference_name ? "border-red-500" : "border-black"
                      }`}
                    id="reference_name"
                    type="text"
                    placeholder="Enter reference name if any"
                    name="reference_name"
                    value={formData.reference_name}
                    onChange={handleChange}
                  />
                  {errors.reference_name && (
                    <p className="text-red-500 text-xs mt-1">{errors.reference_name}</p>
                  )}
                </div>
              </div>
              <div className="mb-4 mt-[30px]">
                <label className="block text-[#000000] text-[14px] leading-[16px] font-chivo  font-medium mb-2" htmlFor="resume">
                  Attach Resume
                </label>
                <input
                  className={`shadow h-[48px] text-sm appearance-none border-[1px] border-b-4  rounded w-full py-2 px-3 text-gray-700 focus:outline-none ${errors.resume ? "border-red-500" : "border-black"
                    }`} id="resume"
                  type="file"
                  name="resume"
                  onChange={handleChange}
                />
                {errors.resume && <p className="text-red-500 text-xs mt-1">{errors.resume}</p>}
              </div>
              <div className="mb-4 mt-[30px]">
                <label className="block text-[#000000] text-[14px] leading-[16px] font-chivo  font-medium mb-2">
                  Gender
                </label>
                <div className="flex gap-[53px] items-center">
                  <div className="flex  items-center">
                    <input
                      id="male"
                      type="radio"
                      name="gender"
                      value="Male"
                      className="mr-2 h-[24px] w-[24px]"
                      checked={formData.gender === "Male"}
                      onChange={handleChange}
                    />
                    <label htmlFor="male" className="mr-4 font-chivo  font-medium text-[14px] leading-[16px]">Male</label>
                  </div>
                  <div className="flex  items-center">
                    <input
                      id="female"
                      type="radio"
                      name="gender"
                      value="Female"
                      className="mr-2 h-[24px] w-[24px]"
                      checked={formData.gender === "Female"}
                      onChange={handleChange}
                    />
                    <label htmlFor="female" className="font-chivo  font-medium text-[14px] leading-[16px]">Female</label>
                  </div>
                </div>
                {errors.gender && <p className="text-red-500 text-xs mt-1">{errors.gender}</p>}

              </div>

              <div className="mb-4  mt-[30px]">
                <div className="flex  items-center">
                  <input
                    id="agree"
                    type="checkbox"
                    className=" mr-2 h-[24px] w-[24px] leading-tight"
                    name="agree"
                    checked={formData.agree}
                    onChange={(e) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        agree: e.target.checked,
                      }));
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        agree: "",
                      }));
                    }}
                  />
                  <label className="text-[#000000] font-chivo  font-normal text-[14px] leading-[16px] " htmlFor="agree">
                    I hereby accept all the Terms and Conditions as well as the Privacy Policy of the company.              </label>
                </div>

                {errors.agree && <p className="text-red-500 text-xs mt-1">{errors.agree}</p>}

              </div>
              <button
                type="submit"
                className={`bg-[#469FD4] w-full  text-white px-4 py-3 mt-6 rounded-md text-center justify-center items-center flex ${loading ? 'cursor-wait opacity-50' : 'cursor-pointer'}`}
                disabled={loading}
              >
                {loading ? (
                  <div className="w-5 h-5 border-4 border-t-4 border-white rounded-full animate-spin"></div> // Spinner
                ) : (
                  " Submit Application"
                )}
              </button>
            </form>
          </div>
        </div>
      )}
      {showThankYouPopup && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-[95%] md:w-[50%] text-center items-center flex flex-col">
            <img width="800" height="600" sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"
              src="https://webkorps-app.s3.us-east-1.amazonaws.com/thankyouImage.webp"
              alt="Thank You"
              className="w-[100px] h-[100px] mb-5"
            />
            <h2 className="text-xl font-bold mb-4">Thank You!</h2>
            <p className="text-gray-700 mb-4">Your application has been submitted successfully.</p>
            <button
              className="bg-[#469FD4] text-white px-4 py-2 rounded-md"
              onClick={() => setShowThankYouPopup(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
}