import React, { useEffect, useRef, useState } from "react";
import Header from "../sharedComponents/Header";
import Footer from "../sharedComponents/footer";
import { Helmet } from "react-helmet-async";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Link, useNavigate } from 'react-router-dom';
import Select from "react-select";
import ReCAPTCHA from 'react-google-recaptcha'; // Import reCAPTCHA
import { ClientsImg, ClientsImg2 } from "../sharedComponents/clinetsImage";
import Slider from "react-slick";

const AboutUs = () => {
  const navigate = useNavigate();

  const offices = [
    {
      location: "Indore, Ind. (Headquarters)",
      address:
        "4th Floor, Winway World Offices,Vijay Nagar, Indore, Madhya Pradesh 452010",
      imgSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/webkorps-office-indore.webp",
      email: "hr@webkorps.com",
      region: "Asia Pacific",
      altText: "webkorps-office-indore"

    },
    {
      location: "Pune, Ind.",
      address:
        "Trios Co-working, 3rd floor, Lalwani Icon, off New Airport Road, Sakore Nagar, Viman Nagar, Pune, Maharashtra 411014",
      imgSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/webkorps-office-pune.webp",
      email: "hr@webkorps.com",
      region: "Asia Pacific",
      altText: 'webkorps-office-pune'
    },
    {
      location: "Sheridan, WY",
      address:
        " 1309 Coffeen Ave. STE 1200,<br/> Sheridan, Wyoming, United States, 82801",
      imgSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/webkorps-office-usa.webp",
      email: "hr@webkorps.com",
      region: "USA",
      altText: "webkorps-office-usa"
    },
  ];
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    location: '',
    contact_number: '',
    message: '',
    recaptcha_token:''

  });
  const [loading, setLoading] = useState(false)
  const handlePhoneChange = (value) => {
    setFormData((prev) => ({ ...prev, contact_number: value }));
    validateField('contactNumber', value);

  };
  const [errors, setErrors] = useState({});
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [captchaValue, setCaptchaValue] = useState(null); // Store reCAPTCHA value

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data, "text conties")
        setCountries(data.countries.map((c) => ({ label: c.label, value: c.value })));
        setSelectedCountry(data.userSelectValue);
        setFormData((prev) => ({ ...prev, location: data.userSelectValue?.label }))

      });
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;

        const response = await fetch(
          `https://geocode.xyz/${latitude},${longitude}?geoit=json`
        );
        const locationData = await response.json();
        const userCountry = countries.find(
          (country) =>
            country.label.toLowerCase() === locationData.country.toLowerCase()
        );

        if (userCountry) {
          setSelectedCountry(userCountry);
          setFormData((prev) => ({ ...prev, location: userCountry?.label }))

        }
      });
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, [countries]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateField(name, value);
  };
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value); 
    setFormData((prev) => ({ ...prev, recaptcha_token: value }));

  };
  const handleSubmit = (e) => {
    e.preventDefault();

    let hasError = false;

    // Validate all fields except "company"
    Object.keys(formData).forEach((field) => {
      validateField(field, formData[field]);
      if (field !== 'company' && !formData[field]) {
        hasError = true;
      }
    });
    
    // Check for validation errors or other error messages
    if (hasError || Object.values(errors).some((error) => error !== '')) {
      return;
    }
    console.log("captcha value ",captchaValue)
    if (!captchaValue) {
      alert('Please verify you are human!');
      return;
    }

    // Prepare form data
    const formDataObj = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataObj.append(key, formData[key]);
    });
console.log(formDataObj ,"formDataObj")
    setLoading(true);

    // Submit form data
    fetch('https://be.webkorps.com:4202/v1/contact_us', {
      method: 'POST',
      body: formDataObj,
    })
      .then((res) => {
        setLoading(false);
        if (res.ok) {
          navigate('/thankyou');
          // Reset form data
          setFormData({
            name: '',
            email: '',
            company: '',
            location: '',
            contact_number: '',
            message: '',
            recaptcha_token:''
          });
        } else {
          alert('Failed to submit form. Please try again later.');
        }
      })
      .catch((err) => {
        console.error('Error submitting form:', err);
        setLoading(false);
      });
  };


  const validateField = (name, value) => {
    let errorMessage = '';
    switch (name) {
      case 'name':
        if (!value) {
          errorMessage = 'Name is required.';
        } else if (!/^[A-Za-z\s]+$/.test(value)) {
          errorMessage = 'Name can only contain letters and spaces.';
        }
        break;
      case 'email':
        if (!value) {
          errorMessage = 'Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          errorMessage = 'Email is invalid.';
        }
        break;
      case 'location':
        if (!value) {
          errorMessage = 'Location is required.';
        }
        break;
      case 'contact_number':
        if (!value) {
          errorMessage = 'Contact Number is required.';
        } else if (value.length <= 10) {
          errorMessage = 'Phone numbers must be more than 10 digits.';
        }
        break;
      case 'message':
        if (!value) {
          errorMessage = 'Message is required.';
        }
        break;
        case 'recaptcha_token':
          if (!value) {
            errorMessage = 'Please verify you are human!';
          }
          break;
      default:
        break;
    }

    // Update error state
    setErrors((prevErrors) => ({ ...prevErrors, [name]: errorMessage }));
  };

  const groupedOffices = offices.reduce((acc, office) => {
    if (!acc[office.region]) {
      acc[office.region] = [];
    }
    acc[office.region].push(office);
    return acc;
  }, {});


  //slider
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false, // Hides the side arrows
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 820, // For iPad Air (820px width in portrait mode)
        settings: {
          slidesToShow: 2, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const webSliderRef = useRef(null);
  const mobSliderRef = useRef(null);
  return (
    <div className="overflow-hidden ">
      <Helmet>
        <title>Contact Us | Connect with Webkorps for IT Solutions and Services</title>
        <meta
          name="description"
          content="Have queries or need expert IT services? Reach out to Webkorps! Our team is ready to assist with tailored IT solutions for your business needs. Contact us today to get started!"
        />
        <link rel="canonical" href="https://www.webkorps.com/contact" />
      </Helmet>
      <Header />
      <div className="flex  flex-col justify-center mt-0 md:mt-[1.0rem] text-center md:text-center xl:text-left ipad-pro:text-left  relative w-full">
        <div className="w-full ">
          <div className="flex  gap-[10%] md:items-center xl:items-start ipad-pro:items-start items-center   px-[8%] xl:px-[160px] xl:pr-[140px] md:px-10 ipad-pro:px-[100px] mt-[48px]   flex-col sm:flex-col md:flex-col xl:flex-row ipad-pro:flex-row  mb-[20px]  ">
            <div className="flex flex-col xl:w-[50%] ipad-pro:w-[100%] w-[100%] xl:mt-[120px]  ipad-pro:mt-[120px] mt-0 md:mt-0 sm:mt-0 ">
              <h1 className="text-[28px] leading-[39px] font-bold mb-4 ">We’re Just One Click Away from Making Magic Happen!</h1>
              <p className="text-[19px] leading-[26px] font-normal text-[#000000] mb-6 xl:w-[70%] xxl:w-[60%] ipad-pro:w-[90%] md:w-full w-full ">
                Let us know your story, and we’ll craft a tailored solution just for you.          </p>
              {/* <p className="text-[19px] leading-[26px] font-normal text-[#000000] mb-6 xl:w-[70%] xxl:w-[60%] ipad-pro:w-[90%] md:w-full w-full">
                <strong className="font-bold">Business Inquiry:</strong><br />
                <a
                  href="mailto:contact@webkorps.com"
                  className="text-blue-500 underline"
                >
                  contact@webkorps.com
                </a>
              </p>
              <p className="text-[19px] leading-[26px] font-normal text-[#000000] mb-6 xl:w-[70%] xxl:w-[60%] ipad-pro:w-[90%] md:w-full w-full">
                <strong className="font-bold">Career:</strong><br />
                <a
                  href="mailto:hr@webkorps.com"
                  className="text-blue-500 underline"
                >
                  hr@webkorps.com
                </a>
              </p> */}

            </div>
            <div className="relative  z-20 bg-white md:w-[480px] xl::w-[480px] w-[112%] ipad-sum:w-[70%] ipad-pro:w-[480px] xl:h-[630px] ipad-pro:h-[630px] md:h-[630px] h-[600px]    rounded-[20px] shadow-lg border border-gray-600">
              <div className="bg-gray-100  border-b border-gray-500  w-[100%] h-30  pb-2 p-6 rounded-tl-[20px] rounded-tr-[20px]">
                <p className="text-[33px] leading-[46px] font-semibold font-quicksand mb-1 text-center">Connect With Our Experts Today!</p>
              </div>
              <form className="bg-white xl:p-[36px] ipad-pro:p-[36px] md:p-[36px] p-0 pt-[20px] justify-items-center xl:justify-items-start ipad-pro:justify-items-start md:justify-items-start rounded-bl-[20px] rounded-br-[20px]" onSubmit={handleSubmit}>
                <div className=" flex gap-[24px]">
                  <div className=" mb-6">
                    <input
                      type="text"
                      name="name"
                      placeholder="Name*"
                      value={formData.name}
                      onChange={handleChange}
                      className={`xl:w-[191px] md:w-[191px] w-[150px] ipad-pro:w-[191px] h-[39px]  text-[19px] leading-[26px] border-b ${errors.name ? 'border-red-600' : 'border-gray-500'
                        }`}
                      required
                    />
                    {errors.name && <p className="text-red-600 text-xs">{errors.name}</p>}
                  </div>
                  <div className=" mb-6">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email*"
                      value={formData.email}
                      onChange={handleChange}
                      className={`xl:w-[191px] md:w-[191px] w-[150px] ipad-pro:w-[191px] h-[39px]  text-[19px] leading-[26px] border-b ${errors.email ? 'border-red-600' : 'border-gray-500'
                        }`}
                      required
                    />
                    {errors.email && <p className="text-red-600 text-xs">{errors.email}</p>}
                  </div>
                </div>
                <div className=" flex  gap-[24px]">
                  <div className=" mb-6">
                    <input
                      type="text"
                      name="company"
                      placeholder="Company"
                      value={formData.company}
                      onChange={handleChange}
                      className={`xl:w-[191px] md:w-[191px] w-[150px] ipad-pro:w-[191px] h-[39px]  text-[19px] leading-[26px] border-b ${errors.company ? 'border-red-600' : 'border-gray-500'
                        }`}
                    />
                    {errors.company && <p className="text-red-600 text-xs">{errors.company}</p>}
                  </div>
                  <div className=" mb-6">
                    <Select
                      options={countries}
                      value={selectedCountry}
                      onChange={(selectedOption) => {
                        setSelectedCountry(selectedOption)
                        setFormData((prev) => ({ ...prev, location: selectedOption?.label }))
                      }}
                      placeholder="Country*"
                      isSearchable
                      className={`xl:w-[191px] md:w-[191px] w-[150px] ipad-pro:w-[191px] h-[39px]  text-[19px] leading-[26px] border-b ${errors.company ? 'border-red-600' : 'border-gray-500'
                        }`}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: "39px",
                          borderColor: '#6B7280',
                          borderTop: 0,
                          borderRight: 0,
                          borderLeft: 0
                        }),
                        menu: (base) => ({
                          ...base,
                          zIndex: 999, // Ensures dropdown overlaps correctly
                        }),
                      }}
                    />
                    {errors.country && <p className="text-red-600 text-xs">{errors.country}</p>}
                  </div>
                </div>
                <div className="mb-6">
                  <div >
                    <PhoneInput
                      international
                      defaultCountry={selectedCountry?.value}
                      value={formData.contact_number}
                      onChange={handlePhoneChange}
                      limitMaxLength={10}
                      placeholder="Contact Number*"
                      className="xl:w-[405px] md:w-[405px] w-[315px] ipad-pro:w-[405px] h-[39px] p-2 text-[19px] leading-[26px] border-b border-gray-500 p-2"
                      required
                    />
                    {errors.contact_number && <p className="text-red-600 text-xs">{errors.contact_number}</p>}
                  </div>
                </div>
                <div className="mb-6">
                  <textarea
                    name="message"
                    placeholder="About your requirement*"
                    value={formData.message}
                    onChange={handleChange}
                    className={`xl:w-[405px] md:w-[405px] w-[315px] ipad-pro:w-[405px] h-[49px] p-2 text-[19px] leading-[26px] border-b ${errors.message ? 'border-red-600' : 'border-gray-500'
                      } overflow-y-auto resize-none`}
                    rows="1"
                    required
                  />

                  {errors.message && <p className="text-red-600 text-xs">{errors.message}</p>}
                </div>
                <div className="mb-6 justify-items-center xl:justify-items-start ipad-pro:justify-items-start md:justify-items-start">
                  {/* Google reCAPTCHA */}
                  <ReCAPTCHA
                    sitekey="6LdEkZoqAAAAAPn6MpmkLFjte5HUZlw2n6fctOKR" // Replace with your reCAPTCHA site key
                    onChange={handleCaptchaChange}
                  />
                  {errors.recaptcha_token && <p className="text-red-600 text-xs">{errors.recaptcha_token}</p>}
                </div>

                <button
                  type="submit"
                  className={`bg-[#469FD4] w-[180px] text-white px-4 py-3 mt-6 rounded-md flex justify-center items-center ${loading ? 'cursor-wait opacity-50' : 'cursor-pointer'}`}
                  disabled={loading}
                >
                  {loading ? (
                    <div className="w-5 h-5 border-4 border-t-4 border-white rounded-full animate-spin"></div> // Spinner
                  ) : (
                    "Submit"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* Clients Section */}
        <div className="Clients  z-0 w-auto h-auto secondary-bg-color ">
          <div className="content mx-auto w-full max-w-screen-xl  text-black">
            <h2 className="font-quicksand font-bold text-[32px] md:text-[36px] lg:text-[48px] leading-tight pt-6 md:pt-12 xl:pl-[110px] xl:pr-0 ipad-pro:pl-[100px] ipad-pro:pr-0">
              Trusted by Industry Leaders Worldwide
            </h2>
          </div>
          <div className="content relative xl:pl-[160px] xl:pr-0 ipad-pro:pl-[100px] ipad-pro:pr-0 text-center flex justify-end pb-0 ipad-prp:pb-0 ipad-sum:pb-0 xl:pb-0  md:pb-0">
            {/* Sliders */}
            <div className="absolute xl:top-10 ipad-pro:top-10 ipad-sum:top-10 md:top-10 top-0 left-1/2 transform -translate-x-1/2 -translate-y-[0%] pb-10  px-[11%] w-full">
              <Slider {...settings} ref={webSliderRef}>
                {ClientsImg.map((image) => (
                  <div key={image.id} className="flex justify-center justify-items-center p-6">
                    <div className="w-[224px] h-[95px]  xl:h-[120px] md:h-[120px] ipad-pro:h-[120px] ipad-sum:h-[120px]  rounded-lg border-2 border-gray-400 p-6 bg-white flex justify-center items-center">
                      <img width="800" height="600" sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"
                        src={image.src}
                        alt={image.alt}
                        className={`h-auto w-auto ${image.fill ? 'filter invert' : ''}`}
                      />
                    </div>
                  </div>
                ))}
              </Slider>

              <Slider {...settings} ref={mobSliderRef}>
                {ClientsImg2.map((image) => (
                  <div key={image.id} className="flex justify-center justify-items-center ">
                    <div className="w-[224px] h-[95px]  xl:h-[120px] md:h-[120px] ipad-pro:h-[120px] ipad-sum:h-[120px]  rounded-lg border-2 border-gray-400 p-9 flex justify-center items-center bg-white">
                      <img width="800" height="600" sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"
                        src={image.src}
                        alt={image.alt}
                        className={`h-auto w-auto ${image.fill ? 'filter invert' : ''}`}
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            {/* Image */}
            <img width="800" height="600" sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"
              src='https://webkorps-app.s3.us-east-1.amazonaws.com/circle.svg'
              alt="circle "
              className="w-full md:w-[570px]  md:pt-10 "
            />
          </div>
        </div>
        {/* contact career */}
        <div className='bg-[#f9f9f9] h-auto  content-center pb-[50px] pt-[50px]'>
          <div className="flex flex-col align-center  items-center  xl:px-[160px] justify-center gap-6   ">
            <div>
              <h2 className="font-quicksand   font-bold text-[22px]    md:text-[25px] md:leading-[30px] xl:text-[37px]  ipad-pro:text-[30px]  ipad-pro:leading-[46px] leading-[25px] xl:leading-[50px] align-center text-center mb-[24px] ">Looking for Career Opportunities</h2>
              <p className="font-quicksand  font-bold text-[22px]    md:text-[25px] md:leading-[30px] xl:text-[20px]  ipad-pro:text-[20px]  ipad-pro:leading-[26px] leading-[25px] xl:leading-[26px] align-center text-center"
              >
                Join a workplace that values creativity, collaboration, and career growth to help you achieve your dreams. Explore exciting roles, unlock your potential, and grow your career with us.
              </p>
            </div>
            <Link to="/join-us"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center align-center justify-center text-white focus:outline-none focus:ring-4 focus:ring-blue-300 font-bold rounded-full text-base text-center md:text-lg  w-[250px] md:w-[250px] xl:w-[350px] ipad-pro:w-[250px]  h-[56px] bg-[#1887C9] font-quicksand"
            >
              Explore Exciting Opportunities
            </Link>
          </div>
        </div>
        {/* Offices Section */}
        <div className="relative  w-full pb-6 md:pb-10 md:pb-[50px] flex flex-col justify-center bg-[#626D78]" >
          <div className=" w-full px-[8%] md:px-10 xl:px-[160px] ipad-pro:px-[100px]">
            <h2 className="text-2xl font-bold text-[40px] leading-[56.2px] text-white  mt-3 md:mt-[50px]">Webkorps Across the Globe </h2>
          </div>
          <div className="  px-[8%] xl:px-[160px] md:px-10 ipad-pro:px-[100px] gap-3 flex  flex-col xl:flex-row  ipad-pro:flex-col mt-5">
            <div className="w-full xl:w-[70%] ipad-pro:w-full">
              <p className="text-[36px] md:text-[40px] leading-[56px] font-bold text-white mb-3">Asia Pacific</p>
              <div className="flex flex-col md:flex-row gap-6 md:gap-0 md:space-x-4 overflow-x-auto">
                {groupedOffices["Asia Pacific"].map((office, index) => (
                  <div key={index} className="bg-white  shadow-md overflow-hidden w-full md:w-[50%] h-[350px] ">
                    <img width="800" height="600" sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy" src={office.imgSrc} alt={office.altText} className="w-full h-48 object-cover" />
                    <div className="p-2 md:p-4">
                      <p className="text-[19px] leading-[26px] font-semibold mb-2">{office.location}</p>
                      <div className="flex flex-col md:flex-row text-center md:text-left items-center md:items-start gap-1"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 13.5C11.2583 13.5 10.5333 13.2801 9.91661 12.868C9.29993 12.456 8.81928 11.8703 8.53545 11.1851C8.25162 10.4998 8.17736 9.74584 8.32206 9.01841C8.46675 8.29098 8.8239 7.6228 9.34835 7.09835C9.8728 6.5739 10.541 6.21675 11.2684 6.07206C11.9958 5.92736 12.7498 6.00162 13.4351 6.28545C14.1203 6.56928 14.706 7.04993 15.118 7.66661C15.5301 8.2833 15.75 9.00832 15.75 9.75C15.7488 10.7442 15.3533 11.6973 14.6503 12.4003C13.9473 13.1033 12.9942 13.4988 12 13.5ZM12 7.5C11.555 7.5 11.12 7.63196 10.75 7.8792C10.38 8.12643 10.0916 8.47783 9.92127 8.88896C9.75098 9.3001 9.70642 9.7525 9.79323 10.189C9.88005 10.6254 10.0943 11.0263 10.409 11.341C10.7237 11.6557 11.1246 11.87 11.561 11.9568C11.9975 12.0436 12.4499 11.999 12.861 11.8287C13.2722 11.6584 13.6236 11.37 13.8708 11C14.118 10.63 14.25 10.195 14.25 9.75C14.2494 9.15345 14.0122 8.5815 13.5903 8.15967C13.1685 7.73784 12.5966 7.5006 12 7.5Z" fill="#1887C9" />
                        <path d="M12 22.5L5.67301 15.0382C5.58509 14.9262 5.49809 14.8135 5.41201 14.7C4.33179 13.276 3.74799 11.5373 3.75001 9.75C3.75001 7.56196 4.6192 5.46354 6.16637 3.91637C7.71355 2.36919 9.81197 1.5 12 1.5C14.188 1.5 16.2865 2.36919 17.8336 3.91637C19.3808 5.46354 20.25 7.56196 20.25 9.75C20.2517 11.5365 19.6682 13.2743 18.5888 14.6978L18.588 14.7C18.588 14.7 18.363 14.9955 18.3293 15.0353L12 22.5ZM6.60975 13.7963C6.60975 13.7963 6.7845 14.0272 6.82425 14.0767L12 20.181L17.1825 14.0685C17.2155 14.0272 17.391 13.7948 17.3918 13.794C18.2747 12.6309 18.7518 11.2103 18.75 9.75C18.75 7.95979 18.0388 6.2429 16.773 4.97703C15.5071 3.71116 13.7902 3 12 3C10.2098 3 8.4929 3.71116 7.22703 4.97703C5.96116 6.2429 5.25001 7.95979 5.25001 9.75C5.24815 11.2112 5.72584 12.6327 6.60975 13.7963Z" fill="#1887C9" />
                      </svg>
                        <p className="text-[13px] leading-[18px] text-[#000000] font-light w-full md:w-[70%]">{office.address} </p></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full xl:w-[35%] ipad-pro:w-[50%] md:w-[50%]">
              <p className="text-[36px] md:text-[40px] leading-[56px] font-bold text-white mb-3">USA</p>
              <div className="bg-white  shadow-md overflow-hidden h-[350px]">
                <img width="800" height="600" sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy" src={groupedOffices["USA"][0].imgSrc} alt={groupedOffices["USA"][0].altText} className="w-full h-48 object-cover" />
                <div className="p-2 md:p-4">
                  <p className="text-[19px] leading-[26px] font-semibold mb-2">{groupedOffices["USA"][0].location}</p>
                  <div className="flex flex-col md:flex-row text-center items-center md:items-start  gap-1"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 13.5C11.2583 13.5 10.5333 13.2801 9.91661 12.868C9.29993 12.456 8.81928 11.8703 8.53545 11.1851C8.25162 10.4998 8.17736 9.74584 8.32206 9.01841C8.46675 8.29098 8.8239 7.6228 9.34835 7.09835C9.8728 6.5739 10.541 6.21675 11.2684 6.07206C11.9958 5.92736 12.7498 6.00162 13.4351 6.28545C14.1203 6.56928 14.706 7.04993 15.118 7.66661C15.5301 8.2833 15.75 9.00832 15.75 9.75C15.7488 10.7442 15.3533 11.6973 14.6503 12.4003C13.9473 13.1033 12.9942 13.4988 12 13.5ZM12 7.5C11.555 7.5 11.12 7.63196 10.75 7.8792C10.38 8.12643 10.0916 8.47783 9.92127 8.88896C9.75098 9.3001 9.70642 9.7525 9.79323 10.189C9.88005 10.6254 10.0943 11.0263 10.409 11.341C10.7237 11.6557 11.1246 11.87 11.561 11.9568C11.9975 12.0436 12.4499 11.999 12.861 11.8287C13.2722 11.6584 13.6236 11.37 13.8708 11C14.118 10.63 14.25 10.195 14.25 9.75C14.2494 9.15345 14.0122 8.5815 13.5903 8.15967C13.1685 7.73784 12.5966 7.5006 12 7.5Z" fill="#1887C9" />
                    <path d="M12 22.5L5.67301 15.0382C5.58509 14.9262 5.49809 14.8135 5.41201 14.7C4.33179 13.276 3.74799 11.5373 3.75001 9.75C3.75001 7.56196 4.6192 5.46354 6.16637 3.91637C7.71355 2.36919 9.81197 1.5 12 1.5C14.188 1.5 16.2865 2.36919 17.8336 3.91637C19.3808 5.46354 20.25 7.56196 20.25 9.75C20.2517 11.5365 19.6682 13.2743 18.5888 14.6978L18.588 14.7C18.588 14.7 18.363 14.9955 18.3293 15.0353L12 22.5ZM6.60975 13.7963C6.60975 13.7963 6.7845 14.0272 6.82425 14.0767L12 20.181L17.1825 14.0685C17.2155 14.0272 17.391 13.7948 17.3918 13.794C18.2747 12.6309 18.7518 11.2103 18.75 9.75C18.75 7.95979 18.0388 6.2429 16.773 4.97703C15.5071 3.71116 13.7902 3 12 3C10.2098 3 8.4929 3.71116 7.22703 4.97703C5.96116 6.2429 5.25001 7.95979 5.25001 9.75C5.24815 11.2112 5.72584 12.6327 6.60975 13.7963Z" fill="#1887C9" />
                  </svg><p className="text-[13px] leading-[18px] text-[#000000] font-light text-left" dangerouslySetInnerHTML={{ __html: groupedOffices["USA"][0].address }} /></div>
                </div>
              </div>
            </div>
          </div>


        </div>


      </div>
      <Footer />
    </div>
  );
};
export default AboutUs;