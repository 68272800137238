import React, { useEffect, useRef } from 'react';
import Footer from './footer';
import './style.css';
import { ClientsImg, ClientsImg2 } from './clinetsImage';
import Technologies from './technologies';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
export default function Main() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false, // Hides the side arrows
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 820, // For iPad Air (820px width in portrait mode)
        settings: {
          slidesToShow: 2, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const webSliderRef = useRef(null);
  const mobSliderRef = useRef(null);
  const webDirection='right'
  const mobDirection='left';

  // Auto-play for web slider
  useEffect(() => {
    const autoPlayWeb = setInterval(() => {
      if (webSliderRef.current) {
        webDirection === 'right' ? webSliderRef.current.slickNext() : webSliderRef.current.slickPrev();
      }
    }, 1000);

    return () => clearInterval(autoPlayWeb);
  }, [webDirection]);

  // Auto-play for mobile slider
  useEffect(() => {
    const autoPlayMob = setInterval(() => {
      if (mobSliderRef.current) {
        mobDirection === 'right' ? mobSliderRef.current.slickNext() : mobSliderRef.current.slickPrev();
      }
    }, 1000);

    return () => clearInterval(autoPlayMob);
  }, [mobDirection]);

  return (
    <>
      {/* <ClientFeedback /> */}
      <Technologies />
      <div className="Clients secondary-bg-color w-auto h-auto">
        <div className="content mx-auto w-full max-w-screen-xl text-center text-black mt-7 md:mt-[4rem]">
          <h1 className="font-quicksand font-bold text-[32px] md:text-[36px] lg:text-[48px] line-height-1 pt-6 md:pt-12">Our Clients</h1>
          <div className='content mx-auto w-[70%] max-w-screen-xl text-center '>
            <h4 className="text-lg  font-normal pt-6">
            Trusted by leading businesses, our platform empowers growth through seamless connections and innovative solutions. We support clients across industries, helping them succeed with a tailored and <br/> efficient marketplace experience.
            </h4>
          </div>

        </div>

        <div className="content relative xl:pl-[160px] xl:pr-0 ipad-pro:pl-[100px] ipad-pro:pr-0 text-center flex justify-end pb-0 ipad-prp:pb-0 ipad-sum:pb-0 xl:pb-0  md:pb-0">
  {/* Sliders */}
  <div className="absolute xl:top-10 ipad-pro:top-10 ipad-sum:top-10 md:top-10 top-0 left-1/2 transform -translate-x-1/2 -translate-y-[0%] pb-10  px-[11%] w-full">
    <Slider {...settings} ref={webSliderRef}>
      {ClientsImg.map((image) => (
        <div key={image.id} className="flex justify-center justify-items-center p-6">
          <div className="w-[224px] h-[95px]  xl:h-[120px] md:h-[120px] ipad-pro:h-[120px] ipad-sum:h-[120px]  rounded-lg border-2 border-gray-400 p-6 bg-white flex justify-center items-center">
            <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"
              src={image.src}
              alt={image.alt}
              className={`h-auto w-auto ${image.fill ? 'filter invert' : ''}`}
            />
          </div>
        </div>
      ))}
    </Slider>

    <Slider {...settings} ref={mobSliderRef}>
      {ClientsImg2.map((image) => (
        <div key={image.id} className="flex justify-center justify-items-center ">
          <div className="w-[224px] h-[95px]  xl:h-[120px] md:h-[120px] ipad-pro:h-[120px] ipad-sum:h-[120px]  rounded-lg border-2 border-gray-400 p-9 flex justify-center items-center bg-white">
            <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"
              src={image.src}
              alt={image.alt}
              className={`h-auto w-auto ${image.fill ? 'filter invert' : ''}`}
            />
          </div>
        </div>
      ))}
    </Slider>
  </div>
  {/* Image */}
  <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"
     src='https://webkorps-app.s3.us-east-1.amazonaws.com/circle.svg'
    alt="circle "
    className="w-full md:w-[657px]  md:pt-10 " 
  />
</div>

      </div>
      <Footer />
    </>
  );
}
