import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../sharedComponents/Header';
import Footer from '../sharedComponents/footer';
import { OfferCard } from './DigitalServices';
import { Helmet } from 'react-helmet-async';


function CloudServices() {
  const location = useLocation();

  const offers = [
    { title: 'Cloud Backup <br/>& Disaster Recovery', details: 'We offer reliable cloud-based backup and disaster recovery solutions, ensuring business continuity and quick recovery in case of any data loss or system failure.' },
    { title: 'Cloud Infrastructure <br/>Management', details: 'Our team manages and optimizes your cloud infrastructure, ensuring performance, cost-efficiency, and security across your cloud environment.' },
    { title: 'DevOps <br/>Automation', details: 'We enable streamlined cloud operations through DevOps automation, accelerating deployment, reducing costs, and ensuring continuous delivery of applications.' },
    { title: 'Cloud <br/>Migration', details: 'We seamlessly transition your business to the cloud, ensuring minimal disruption and enhanced scalability, security, and efficiency for your operations.' },
    { title: 'SaaS Development <br/>& Integration', details: 'We develop and integrate custom SaaS solutions, allowing you to deliver software as a service with seamless cloud functionality and user-friendly experiences.' },
    { title: 'Cloud Security <br/>Services', details: 'We provide comprehensive cloud security solutions to protect your data and applications, ensuring compliance with industry standards and safeguarding against threats.' },
  ];
  const reasons = [
    "Cloud Expertise and Certifications: Our team comprises certified cloud professionals with deep expertise across various cloud platforms, ensuring you receive best-in-class solutions tailored to your specific requirements.",
    "Multi-Cloud Capabilities: We offer multi-cloud solutions, enabling you to leverage the strengths of different cloud providers and avoid vendor lock-in while ensuring seamless integration and interoperability.",
    "Robust Security and Compliance: Security and compliance are at the forefront of our cloud solutions. We implement industry-leading security practices and adhere to strict compliance standards, ensuring your data and applications are safeguarded.",
    "Cost Optimization: Our cloud solutions are designed to optimize resource utilization and cost efficiency, helping you achieve significant cost savings while maintaining high performance and scalability.",
    "Continuous Support and Innovation: Our commitment extends beyond the initial implementation. We provide ongoing support, maintenance, and continuous innovation, ensuring your cloud solutions remain up-to-date and aligned with the latest technologies and best practices."
  ];

  const splitIndex = Math.ceil(reasons.length / 2);

  return (
    <div className=' overflow-hidden'>
    <Helmet>
    <link rel="canonical" href="https://www.webkorps.com/cloud-services" />
    </Helmet>
      <Header />
      <div className="w-full text-center md:text-left overflow-hidden">
        <div className="flex flex-col md:flex-col ipad-pro:flex-row xl:flex-row items-center px-[5%] xl:px-[160px] ipad-pro:px-[100px] justify-center text-center lg:text-left pb-10 p-6 bg-[#FAFAFA]">
          <div className='content   w-full  flex flex-col '>
            <h1 className="text-[33px] leading-[38px] xl:leading-[46.2px] font-bold  w-[100%]">Cloud Solutions</h1>
            <h2 className="font-quicksand text-[28px] leading-[39.2px] font-bold text-center xl:text-left  ipad-pro:text-left text-[#072125] mt-3 md:mt-4 w-full">
            Elevate Your Business with Our Scalable Cloud Solutions.
            </h2>
            <p className="text-[#072125] mt-[20px] text-[18px] leading-[27.3px]  xl:w-[670px] ipad-pro:w-[350px]">
            Our cloud solutions empower your business with flexibility, scalability, and security, enabling seamless growth and innovation. Whether you’re migrating existing infrastructure or building a new cloud-native application, we offer expert guidance and services to optimize performance and reduce costs. Tailored to your specific needs, our solutions ensure you can scale effortlessly while maintaining robust security and compliance, helping you achieve long-term success in a dynamic business landscape.         </p>
          </div>
          <div className='content  mt-6 xl:mt-0 ipad-pro:mt-6 md:mt-6  w-full  items-center  flex flex-col xl:items-end ipad-pro:items-end md:items-center '>
            <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy" src="https://webkorps-app.s3.us-east-1.amazonaws.com/cloud-solution-services.jpg" alt="cloud-solution-services" className="rounded-lg shadow-md w-full lg:w-[385px] lg:h-[415px] max-w-md" />
          </div>
        </div>

        {/* Offers Section */}
        <div className='flex lg:flex-row items-center  justify-center md:justify-start text-center  md:mx-[50px] xl:ml-[203px] xl:mr-[213px] ipad-pro:mx-[100px] mt-[52px]   w-full mb-[28px]'>
          <h1 className="text-[40px] leading-[56px] font-bold text-center">What We Offer?</h1>
        </div>
        <div className="relative flex flex-col md:flex-row lg:flex-row flex-wrap justify-between w-full md:px-[30px] xl:pl-[203px] xl:pr-[213px] ipad-pro:px-[85px] px-[4%] mb-[89px]">
          {offers.map((offer, index) => (
            <div
              key={offer.id}
              className="relative z-10 w-full sm:w-full md:w-1/2 xl:w-[45%] ipad-pro:w-[50%] p-5"
            >
              <OfferCard key={index} title={offer.title} details={offer.details} />
            </div>
          ))}
          <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"
             src="https://webkorps-app.s3.us-east-1.amazonaws.com/dot-elements.svg"
            alt="dot-elements"
            className="absolute bottom-[-20px] left-[86px] z-0 w-[27%]"
          />
        </div>




        {/* Why Choose Section */}
        <div className="px-[7%] xl:px-[304px]  ipad-pro:px-[100px] rounded-[10px]shadow-md relative">
          <div className="relative">
            <h3 className="text-2xl font-bold text-center mb-4 md:mb-[32px]">
            Why Choose Webkorps for Your Cloud Solutions?  
            </h3>

            <div className="relative w-full">
              <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"
                src={'https://webkorps-app.s3.us-east-1.amazonaws.com/circleleft.svg'}
                alt="circleleft"
                className="absolute top-0 left-[40%] transform -translate-x-[65%] translate-y-[70%] w-[90%] xl:translate-y-[70%] xl:-translate-x-[115%] max-w-[800px] h-[70%] xl:h-[60%] xl:w-[50%]  pointer-events-none z-0"
              />
              <ul className="relative grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-8 text-gray-700 z-10 pb-[85px]">
                {reasons.map((reason, index) => {
                  const [title, description] = reason.split(': ');

                  return (
                    <li key={index} className="flex mb-[30px]">
                      <b className="text-[33px] leading-[46px] font-bold font-quicksand text-[#20484F]">
                        {(index + 1).toString().padStart(2, '0')}
                      </b>
                      <p className="ml-[10px] mt-1 text-[13px] leading-[18px] font-normal font-quicksand text-[#072125]">
                        <strong className="mb-[10px] block text-[19px] leading-[26px] font-bold font-quicksand text-[#072125]">
                          {title}
                        </strong>
                        {description}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CloudServices;
