import React, { useState } from 'react';

const Technologies = () => {
    const techStack = [
        { name: 'Ruby', category: 'Backend', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/ruby.svg", altText: 'ruby' },
        { name: 'Angular', category: 'Frontend', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/angular.svg", altText: 'angular' },
        { name: 'React', category: 'Frontend', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/react.svg", altText: 'react' },
        { name: 'VueJs', category: 'Frontend', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/vue-js.svg", altText: "vue-js" },
        { name: 'Ruby & Rails', category: 'Backend', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/ruby-on-rails.svg", altText: 'ruby-on-rails' },
        { name: 'JavaScript', category: 'Frontend', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/javascript.svg", altText: "javascript" },
        { name: 'Java', category: 'Backend', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/java.svg", altText: "java" },
        { name: 'Kotlin', category: 'Mobile', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/kotlin.svg", altText: 'kotlin' },
        { name: 'PHP', category: 'Backend', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/php.svg", altText: "php" },
        { name: 'AWS', category: 'Cloud', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/aws.svg", altText: 'aws' },
        { name: 'Azure', category: 'Cloud', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/azure.svg", altText: 'azure' },
        { name: 'Kubernetes', category: 'DevOps', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/kubernetes.svg", altText: 'kubernetes' },
        { name: 'Docker', category: 'DevOps', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/docker.svg", altText: 'docker' },
        { name: 'Figma', category: 'Design', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/figma.svg", altText: 'figma' },
        { name: 'Flutter', category: 'Mobile', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/flutter.svg", altText: 'flutter' },
        { name: 'Ionic', category: 'Mobile', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/ionic.svg", altText: "ionic" },
        { name: 'Python', category: 'Backend', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/python.svg", altText: "python" },
        { name: 'Swift', category: 'Mobile', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/swift.svg", altText: "swift" },
        { name: 'AWS', category: 'Database', logo: "https://webkorps-app.s3.us-east-1.amazonaws.com/aws.svg", altText: 'aws' },
    ];

    const [selectedCategory, setSelectedCategory] = useState('Frontend');

    const categories = ['Frontend', 'Backend', 'Mobile','Database', 'Cloud', 'DevOps', 'Design','CMS'];

    return (
        <div className="flex flex-col items-center justify-center   mt-14 xl:mt-14 ipad-pro:mt-10 md:mt-10">
            <div className="flex flex-col w-full  xl:flex-row ipad-pro:flex-col  items-center md:items-start mb-6 md:mb-8 px-[7%] md:px-[7%] xl:px-[160px] ipad-pro:px-[100px] space-y-4 md:space-y-0 md:gap-8 space-x-1 lg:gap-[40px] md:space-x-1">
                <h1 className="text-[32px] md:text-[36px] lg:text-[48px] text-center md:text-left font-bold   leading-[1.4] ">
                    <span className='font-bold'>Technologies</span><br className='md:hidden block ipad-pro:hidden xl:block ' />
                    <span className="md:whitespace-nowrap md:ml-3 ml-0 ipad-pro:ml-3 xl:ml-0">
                    We Are Expert at
                    </span></h1>
                <div>
                    <p className='pb-3 text-center md:text-left'><b >Discover the Power Behind Our Digital Solutions.</b><br /></p>
                    <p className="text-lg text-[#000000] leading-relaxed text-center md:text-left pr-0 md">
                    We’re passionate about technology that delivers results. By harnessing cutting-edge tools and frameworks, we transform complex challenges into streamlined solutions. Our tech stack isn’t just about using the latest trends - it’s about choosing the right technologies to bring your vision to life.
                </p>
            </div>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 xl:grid-cols-6 ipad-pro:grid-cols-3  gap-x-32 gap-y-16 justify-items-center  px-[14%] flex  w-full">
                {techStack.map((tech, index) => (
                    <div key={index} className="flex flex-col items-center">
                        <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy" src={tech.logo} alt={`${tech?.altText}`} className="w-[80px] h-[80px] lg:w-[80px] lg:h-[80px] md:w-[850px] md:h-[80px] m-2" />
                    </div>
                ))}
            </div>
            {/* Filters */}
            {/* <div className="flex flex-col w-full  xl:flex-row ipad-pro:flex-col  items-center md:items-start mb-6 md:mb-8 px-[7%] md:px-[7%] xl:px-[160px] ipad-pro:px-[100px] space-y-4 md:space-y-0 md:gap-8 space-x-1 lg:gap-[40px] md:space-x-1">
        {categories.map((category) => (
            <button
                key={category}
                className={`px-6 py-3 text-lg font-medium border-b-2 transition-all duration-300 ${
                    selectedCategory === category
                        ? 'text-[#1887C9] border-[#1887C9]'
                        : 'text-gray-500 border-transparent hover:text-[#1887C9] hover:border-gray-300'
                }`}
                onClick={() => setSelectedCategory(category)}
            >
                {category}
            </button>
        ))}
    </div> */}

            {/* Technology Grid */}
            {/* <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-6 gap-x-16 gap-y-10 w-full px-[7%] md:px-[7%] xl:px-[130px] ipad-pro:px-[100px]">
                {techStack
                    .filter((tech) => tech.category === selectedCategory)
                    .map((tech, index) => (
                        <div key={index} className="flex flex-col items-center">
                            <img
                                src={tech.logo}
                                alt={tech.altText}
                                className="w-[80px] h-[80px] mb-2"
                            />
                            <p className="text-center">{tech.name}</p>
                        </div>
                    ))}
            </div> */}
        </div>
    );
};

export default Technologies;
