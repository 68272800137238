import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Header from '../sharedComponents/Header';
import Footer from '../sharedComponents/footer';
import { OfferCard } from './DigitalServices';
import { Helmet } from 'react-helmet-async';
import CircleLeft from './../../assets/images/CircleLeft.svg'


function WebService() {
  const location = useLocation();

  const offers = [
    { title: 'Front-End <br/> Development', details: 'Captivating and intuitive user interfaces combine visual appeal with easy navigation, enhancing user satisfaction. By focusing on seamless interactions, these designs boost engagement and create a smooth user experience.' },
    { title: 'Content Management System (CMS)', details: 'We create custom CMS solutions tailored to your needs, offering an intuitive interface and powerful backend for seamless content management and easy website updates.' },
    { title: 'Back-End <br/> Development', details: 'Building robust and efficient backend systems ensures smooth performance and scalability. By focusing on data handling, security, and seamless integration, the backend creates a stable foundation that enhances the overall user experience.' },
    { title: 'Web <br/> Applications', details: 'We develop custom web applications tailored to your business needs, offering intuitive user interfaces and powerful backends for seamless functionality and performance across all devices.' },
    { title: 'Full-Stack <br/>Development', details: 'Full stack development combines both frontend and backend expertise to deliver a complete, seamless user experience. Integrating intuitive interfaces with robust backend systems, ensures efficient performance, scalability, and a cohesive flow between user interactions and data management.' },
    { title: 'E-Commerce <br/>Services', details: 'We provide custom e-commerce solutions designed to fit your business, delivering user-friendly interfaces and secure backends for seamless shopping experiences and smooth transactions.' },
  ];

  const reasons = [
    "User-Centric Design: Our web design philosophy centers around creating exceptional user experiences. We prioritize intuitive navigation, compelling visuals, and seamless functionality to captivate and engage your audience.",
    "Agile Development Methodology: We embrace an agile development approach, fostering continuous collaboration, iteration, and rapid adaptation to ensure your web solutions align with your evolving needs and market trends.",
    "Cross-Browser Compatibility: Our web solutions are meticulously tested for compatibility across various browsers and devices, ensuring a consistent and reliable experience for your users, regardless of their preferred platform.",
    "Performance Optimization: We understand the importance of fast load times and smooth performance for a positive user experience. Our web solutions are optimized for speed and efficiency, ensuring your visitors enjoy a seamless browsing experience.",
    "Ongoing Support and Maintenance: Our commitment extends beyond the initial launch. We provide comprehensive support and maintenance services to keep your web solutions up-to-date, secure, and performing at their best."
  ];

  const splitIndex = Math.ceil(reasons.length / 2);
  const firstHalf = reasons.slice(0, splitIndex);
  const secondHalf = reasons.slice(splitIndex);
  return (
    <div className=' overflow-hidden'>
      <Helmet>
        <title>Leading Web Development Company | Custom Web Solutions</title>
        <meta
          name="description"
          content="Transform your business with our expert web development services. As a leading web development company, we build custom and high-performing web applications tailored to meet your business goals."
        />
        <meta property="og:url" content="https://www.webkorps.com/web-development" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Leading Web Development Company | Custom Web Solutions" />
        <meta property="og:description" content="Transform your business with our expert web development services. As a leading web development company, we build custom and high-performing web applications tailored to meet your business goals." />
        <meta property="og:image" content="https://webkorps-app.s3.us-east-1.amazonaws.com/Web+Development+Company.webp" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="webkorps.com" />
        <meta property="twitter:url" content="https://www.webkorps.com/web-development" />
        <meta name="twitter:title" content="Leading Web Development Company | Custom Web Solutions" />
        <meta name="twitter:description" content="Transform your business with our expert web development services. As a leading web development company, we build custom and high-performing web applications tailored to meet your business goals." />
        <meta name="twitter:image" content="https://webkorps-app.s3.us-east-1.amazonaws.com/Web+Development+Company.webp" />
        <link rel="canonical" href="https://www.webkorps.com/web-development" />

      </Helmet>
      <Header />
      <div className="w-full text-center md:text-left overflow-hidden">
        <div className="flex flex-col md:flex-col ipad-pro:flex-row xl:flex-row items-center px-[5%] xl:px-[160px] ipad-pro:px-[100px] justify-center text-center lg:text-left pb-10 p-6 bg-[#FAFAFA]">
          <div className='content   w-full  flex flex-col '>
            <h1 className="text-[33px] leading-[38px] xl:leading-[46.2px] font-bold  w-[100%]">Web Solutions</h1>
            <h2 className="font-quicksand text-[28px] leading-[39.2px] font-bold text-center xl:text-left  ipad-pro:text-left text-[#072125] mt-3 md:mt-4 w-full">
              Elevate Your Online Presence
            </h2>
            <p className="text-[#072125] mt-[20px] text-[18px] leading-[27.3px]  xl:w-[670px] ipad-pro:w-[350px]">
              In the digital age, having a strong online presence is essential for businesses to thrive. At Webkorps, we understand the importance of creating a seamless and engaging web experience that resonates with your audience and drives conversions. Our cutting-edge web solutions are tailored to meet your unique needs and propel your online success.           </p>
            <Link to="/contact"
              className="px-4 py-2 text-white rounded hover:bg-blue-600 hover:border-b-2 hover:border-blue-500 bg-[#1887C9] w-[120px] mt-[20px]"
            >
              Talk to Us
            </Link>
          </div>
          <div className='content  mt-6 xl:mt-0 ipad-pro:mt-6 md:mt-6  w-full  flex flex-col  items-center  xl:items-end ipad-pro:items-end md:items-center '>
            <img width="800" height="600" sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy" src="https://webkorps-app.s3.us-east-1.amazonaws.com/leading-web-development-company.webp" alt="leading-web-development-company" className="rounded-lg shadow-md w-full lg:w-[385px] lg:h-[415px] max-w-md" />
          </div>
        </div>
        {/* <div className="px-[7%] xl:px-[304px]  ipad-pro:px-[100px]  relative">
          <h3 className="font-quicksand text-[28px] leading-[39.2px] font-bold text-center xl:text-center  ipad-pro:text-center text-[#072125] mt-3 md:mt-4 w-full">
            Why Web Solutions ?
          </h3>
          <p className="text-[#072125] text-center mt-[20px] text-[18px] leading-[27.3px] ">
            At AFQ Tech, we believe in a systematic approach for any project be it complex or simple. We are a group of individuals with a various set of skill set varies from Digital Marketing to IoT/Robotics solutions. We have our dedicated team for your project which uses various methods such as agile Scrum & agile Kanban. We ensure top-notch quality, on-time delivery, and agility for your project.
          </p>
        </div> */}
        {/* Offers Section */}
        <div className='flex lg:flex-row items-center  justify-center md:justify-start text-center  md:mx-[50px] xl:ml-[203px] xl:mr-[213px] ipad-pro:mx-[100px] mt-[52px]   w-full mb-[28px]'>
          <h1 className="text-[40px] leading-[56px] font-bold text-center">What We Offer?</h1>
        </div>
        <div className="relative flex flex-col md:flex-row lg:flex-row flex-wrap justify-between w-full md:px-[30px] xl:pl-[203px] xl:pr-[213px] ipad-pro:px-[85px] px-[4%] mb-[89px]">
          {offers.map((offer, index) => (
            <div
              key={offer.id}
              className="relative z-10 w-full sm:w-full md:w-1/2 xl:w-[45%] ipad-pro:w-[50%] p-5"
            >
              <OfferCard key={index} title={offer.title} details={offer.details} />
            </div>
          ))}
          <img width="800" height="600" sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"
            src="https://webkorps-app.s3.us-east-1.amazonaws.com/dot-elements.svg"
            alt="dot-elements"
            className="absolute bottom-[-20px] left-[86px] z-0 w-[27%]"
          />
        </div>




        {/* Why Choose Section */}
        <div className="px-[7%] xl:px-[304px]  ipad-pro:px-[100px] rounded-[10px] shadow-md relative">
          <div className="relative">
            <h3 className="text-2xl font-bold text-center mb-4 md:mb-[32px]">
              Why Choose Webkorps for Your Web Solutions?
            </h3>

            <div className="relative w-full">
              <img width="800" height="600" sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"
                src={'https://webkorps-app.s3.us-east-1.amazonaws.com/circleleft.svg'}
                alt="circleleft"
                className="absolute top-0 left-[40%] transform -translate-x-[65%] translate-y-[70%] w-[90%] xl:translate-y-[70%] xl:-translate-x-[115%] max-w-[800px] h-[70%] xl:h-[60%] xl:w-[50%]  pointer-events-none z-0"
              />
              <ul className="relative grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-8 text-gray-700 z-10 pb-[85px]">
                {reasons.map((reason, index) => {
                  const [title, description] = reason.split(': ');

                  return (
                    <li key={index} className="flex mb-[30px]">
                      <b className="text-[33px] leading-[46px] font-bold font-quicksand text-[#20484F]">
                        {(index + 1).toString().padStart(2, '0')}
                      </b>
                      <p className="ml-[10px] mt-1 text-[13px] leading-[18px] font-normal font-quicksand text-[#072125]">
                        <strong className="mb-[10px] block text-[19px] leading-[26px] font-bold font-quicksand text-[#072125]">
                          {title}
                        </strong>
                        {description}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default WebService;
