import React from 'react';
import Hexa from '../subComponents/Hexa';

const icons = [
    { id: 1, name: 'Internet Of Things', icon:"https://webkorps-app.s3.us-east-1.amazonaws.com/iot.webp" , isImage: true,altText:'iot' },
    { id: 2, name: 'AI & ML', icon:"https://webkorps-app.s3.us-east-1.amazonaws.com/ai-and-ml.webp" , isImage: true,altText:'ai-and-ml' },
    { id: 3, name: 'Data Analytics', icon:"https://webkorps-app.s3.us-east-1.amazonaws.com/data-analytics.webp" , isImage: true ,altText:'data-analytics'},
    { id: 4, name: 'Robotic Process Automation', icon:"https://webkorps-app.s3.us-east-1.amazonaws.com/robotic-process-automation.webp" , isImage: true  ,altText:"robotic-process-automation"},
    { id: 5, name: 'Cyber Security', icon:"https://webkorps-app.s3.us-east-1.amazonaws.com/cyber-security.webp" , isImage: true ,altText:'cyber-security'},
    { id: 6, name: 'Salesforce', icon:"https://webkorps-app.s3.us-east-1.amazonaws.com/salesforce.webp" , isImage: true ,altText:'salesforce'},
    { id: 7, name: 'Blockchain', icon:"https://webkorps-app.s3.us-east-1.amazonaws.com/blockchain.webp" , isImage: true,altText:'blockchain' },
    { id: 8, name: 'SAP', icon:"https://webkorps-app.s3.us-east-1.amazonaws.com/sap.webp" , isImage: true ,altText:'sap'}
];



const App = () => {
    return (
        <div className='mt-14  w-[100vw] h-auto overflow-hidden'>
            <div className='px-[7%] xl:px-[160px] ipad-pro:px-[100px] w-full '>
                <h1 className="font-quicksand text-center md:text-left lg:text-left sm:text-center text-[32px] md:text-[36px] lg:text-[48px] font-bold leading-tight text-left ">
                    Top Integrations
                </h1>
                <div className="flex flex-col items-center  justify-center md:mt-12 mt-0 mb-7">
                    <div className="w-full sm:w-[90%] lg:w-[75%]">
                        <div className="grid grid-cols-2   md:grid-cols-4   justify-items-center">
                            {icons.slice(0, 4).map(icon => (
                                <Hexa key={icon.id} imageSrc= {icon.icon} text={icon.name} altText={icon.altText} />
                            ))}
                        </div>
                    </div>
                    <div className="w-full ml-0 md:ml-[10%] sm:w-[90%] lg:w-[75%] md:mt-0 mt-0">
                        <div className="grid grid-cols-2   md:grid-cols-4    justify-items-center">
                            {icons.slice(4).map(icon => (
                                <Hexa key={icon.id} imageSrc= {icon.icon} text={icon.name} altText={icon.altText}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default App;
