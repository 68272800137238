import React, { useEffect, useState } from "react";
import { useParams, Link } from 'react-router-dom';
import Header from "../sharedComponents/Header";
import Footer from "../sharedComponents/footer";

export default function JoinUsDetails() {
  const { id } = useParams();
  const [jobDetails, setJobDetails] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false)

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      fetch(`https://be.webkorps.com:4202/v1/career_jobs/${id}`)
        .then(response => response.json())
        .then(data => {
          if (data) {
            setJobDetails(data);
          }
        })
        .catch(error => console.error('Error fetching job data:', error))
        .finally(() => setIsLoading(false));
    }
  }, [id]);

  const items = [
    { name: 'Location', key: 'location', image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/locationIcon.webp' },
    { name: 'Job Type', key: 'job_type', image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/jobTypeIcon.webp' },
    { name: 'Date posted', key: 'created_at', image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/calendarIcon.webp' },
    { name: 'Experience', key: 'experience', image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/ExperienceIcon.webp' },
    { name: 'Working Hours', key: 'shift_start_time', image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/Hoursicon.webp' },
    { name: 'Working Days', key: 'working_days', image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/WorkingIcon.webp' },
    { name: 'Vacancy', key: 'number_of_vacancy', image: 'https://webkorps-app.s3.us-east-1.amazonaws.com/vacancyIcon.webp' },
  ];

  const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    phone_number: '',
    reference_name: '',
    gender: '',
    job_location: '',
    agree: false
  });
  const [showThankYouPopup, setShowThankYouPopup] = useState(false);


  const validateForm = () => {
    const newErrors = {};

    // Validation rules for each field
    if (!formData.full_name.trim()) {
      newErrors.full_name = "Full Name is required.";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid.";
    }
    if (!formData.phone_number.trim()) {
      newErrors.phone_number = "Phone Number is required.";
    } else if (!/^\d{10}$/.test(formData.phone_number)) {
      newErrors.phone_number = "Phone Number must be 10 digits.";
    }
    if (!formData.gender.trim()) {
      newErrors.gender = "Gender is required.";
    }
    if (!formData.resume) {
      newErrors.resume = "Resume is required.";
    }
    if (!formData.job_location.trim()) {
      newErrors.job_location = "Job Location is required.";
    }
    if (!formData.agree) {
      newErrors.agree = "You must accept the Terms and Conditions.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'resume') {
      setFormData(prevState => ({
        ...prevState,
        [name]: files[0],
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }

    if (name === 'email' && value && !/\S+@\S+\.\S+/.test(value)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        email: "Email is invalid.",
      }));
    } else {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: '',
      }));
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const formDataObj = new FormData();
    Object.keys(formData).forEach(key => {
      formDataObj.append(key, formData[key]);
    });

    try {
      const url = 'https://be.webkorps.com:4202/v1/job_applies/send_notifications';
      setFormLoading(true)
      const response = await fetch(url, {
        method: 'POST',
        body: formDataObj,
      });
      if (response.ok) {
        console.log('Form submitted successfully!');
        setFormData({
          full_name: '',
          email: '',
          phone_number: '',
          reference_name: '',
          gender: '',
          job_location: '',
          agree:false
        });
        setFormLoading(false)
        setShowThankYouPopup(true); // Show Thank You popup
      } else {
        alert('Form submission failed!', response.statusText);
        setFormLoading(false)
      }
    } catch (error) {
      setFormLoading(false)
      console.error('Error:', error);
    }
  };
  useEffect(() => {
    if (showThankYouPopup) {
      const timer = setTimeout(() => {
        setShowThankYouPopup(false);
      }, 5000); 
      return () => clearTimeout(timer);
    }
  }, [showThankYouPopup]);
  return (
    <div className="overflow-hidden ">
      <Header />
      <div className="flex justify-center overflow-hidden text-center md:text-left md:mt-4 relative w-full h-[15rem] md:h-[25rem]">
        <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"

          src="https://webkorps-app.s3.us-east-1.amazonaws.com/join-us.webp"
          alt="join-us"
          className="w-full h-full object-cover opacity-70"
        />
      <div
  className="absolute inset-0 flex items-center justify-center flex-col bg-gradient-to-t from-[#001833] to-[#001833] opacity-[0.85]"
>

          <h1 className="text-[28px] leading-[39px] text-white font-bold font-quicksand ">{jobDetails?.designation}</h1>
          <h2 className="text-[13px] leading-[18px] text-white mt-[10.5px]">
            Job Type: {jobDetails?.job_type
              .replace(/_/g, " ")
              .replace(/\b\w/g, (char) => char.toUpperCase())} <span className="ml-2">No of Vacancies: {jobDetails?.number_of_vacancy}</span>
          </h2>
          <button onClick={() => document.getElementById('apply-form').scrollIntoView({ behavior: 'smooth' })}
            className="text-white text-[16px] leading-[22px] primary-button mt-[32px] font-bold">
            Apply Now
          </button>
        </div>
      </div>
      <section className="content px-[7%] xl:px-[160px] ipad-pro:px-[100px] w-full text-center text-dark job-section-blog p-4">
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <div className="loader">Loading...</div>
          </div>
        ) : (
          <div className="grid  grid-cols-1 md:grid-cols-3 ipad-pro:gap-4 md:gap-4 gap-0  xl:gap-4 mt-3 md:mt-10">
            <div className="col-span-2 ipad-pro:w-[90%] xl:w-[90%] md:w-[90%] w-full   ">
              <h1 className=" basic-black  text-[33px] md:text-[33px] leading-[46px] font-bold md:text-left">
                {jobDetails?.designation}
              </h1>
              <div className="md:mt-10">
                <p className="text-[23px] leading-[32px] basic-black mt-1 md:mt-4 md:text-left font-bold">
                  Who Are We Looking For
                </p>
                <div className="pt-2 md:pt-5 text-[16px] leading-[23px] md:text-left break-words">

                  {jobDetails?.skills_requirements}
                </div>
              </div>
              <div className="md:mt-1 md:pt-2">
                <p className="text-[23px] leading-[32px]  basic-black mt-3 md:mt-4 md:text-left font-bold">
                  Bonus Points for Familiarity with
                </p>
                <div className="pt-2 md:pt-5  text-[16px] leading-[23px] md:text-left break-words" >
                  {jobDetails?.additional_skills}
                </div>
              </div>
              <div className="mt-1 pt-2">
                <p className="text-[23px] leading-[32px]  basic-black mt-4 md:text-left font-bold ">
                  Educational Requirement
                </p>
                <div className="pt-3 md:text-left  text-[16px] leading-[23px] break-words">
                  {jobDetails?.educational_requirements}
                </div>
              </div>
            </div>
            <div className="bg-[#F9F9F9] border-[#D4D4D4] h-[960px] md:h-[920px] ipad-pro:h-[920px] xl:h-[920px] border-[0.25px] mt-3 md:mt-0 md:p-0 pb-5">
              <div className="mt-4 job-details justify-center items-center flex flex-col md:block">
                <button className="text-white ipad-pro:apply-button  apply-button  md:apply-button-mini md:w-[150px] xl:apply-button mt-4  text-[19px] leading-[26px] font-bold md:mb-5" onClick={() => document.getElementById('apply-form').scrollIntoView({ behavior: 'smooth' })}>Apply Now</button>
                <h1 className=" text-[#000000] p-4 font-bold font-rubik text-[18px] leading-[24px] text-left pt-8 md:pb-3">
                  Job Summary
                </h1>
                {items.map((item, index) => (
                  <div key={index} className="p-4 flex flex-col md:flex-row justify-center md:justify-start items-center relative   pt-0 md:pb-10">
                    <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy" src={item.image}  alt="text" className="mb-2 w-[30px] w-[38px] text-center md:text-left md:mb-0" />
                    <div className="xl:ml-5 ml-0 ipad-pro:ml-5 md:ml-5 ">
                      <h2 className="  text-[17px] leading-[24px]  md:text-left font-rubik  items-name text-center text-[#D4D4D4]">{item.name}
                      </h2>
                      <span className="block font-medium text-[17px] leading-[24px] md:text-left font-rubik  text-center text-[#001833]">{` ${item.name === 'Date posted' ? jobDetails?.[item.key]?.split('T')[0] : jobDetails?.[item.key]
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (char) => char.toUpperCase())}\n`}</span>
                    </div>
                  </div>
                ))}
                <Link to={`/join-us`} className="text-left float-left text-[17px] leading-[24px] font-rubik  font-medium pt-8 md:ml-5 underline">View all job</Link>
              </div>
            </div>
          </div>
        )}
      </section>
      <section className="application-form mt-10 px-[7%] md:px-0">
        <div id='apply-form' className="w-full md:w-[720px] ipad-pro:w-[920px] xl:w-[920px] md:mx-[20px] ipad-pro:m-auto xl:m-auto  h-auto  m-auto">
          <form className="bg-white shadow-[#5E70CD4D] h-auto xl:h-[1088px]  justify-items-center rounded-[30px]  content-center mb-[73px]  shadow-2xl overflow-hidden  z-50 "
            onSubmit={handleSubmit}
            encType="multipart/form-data">
            <div className="md:w-[558px] h-auto px-[5%] md:px-0 pb-[59px]">
              <div className="mb-4 mt-[30px]  ">
                <label className="block text-[#000000] text-[14px] leading-[16px] font-chivo  font-medium mb-2" htmlFor="fullname">
                  Full Name
                </label>
                <input
                  className={`shadow h-[48px] text-[14px] leading-[16px] appearance-none border-[1px] border-b-4 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none ${errors.full_name ? 'border-red-500' : 'border-black'
                    }`}
                  id="fullname"
                  type="text"
                  placeholder="Enter Fullname"
                  name="full_name"
                  value={formData.full_name}
                  onChange={handleChange}
                />
                {errors.full_name && <p className="text-red-500 text-xs mt-2">{errors.full_name}</p>}
              </div>

              <div className="mb-4 mt-[30px]">
                <label className="block text-[#000000] text-[14px] leading-[16px] font-chivo  font-medium mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  className={`shadow h-[48px] text-[14px] appearance-none border-[1px] border-b-4  rounded w-full py-2 px-3 text-gray-700 focus:outline-none ${errors.email ? "border-red-500" : "border-black"
                    }`}
                  id="email"
                  type="email"
                  placeholder="Enter Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <p className="text-red-500 text-xs mt-2">{errors.email}</p>
                )}
              </div>

              <div className="mb-4 mt-[30px]">
                <label className="block text-[#000000] text-[14px] leading-[16px] font-chivo  font-medium mb-2" htmlFor="phone">
                  Phone Number
                </label>
                <div className="flex">
                  <input
                    className={`shadow h-[48px] text-[14px] appearance-none border-[1px] border-b-4  rounded w-full py-2 px-3 text-gray-700 focus:outline-none ${errors.phone_number ? "border-red-500" : "border-black"
                      }`}
                    id="phone_number"
                    type="text"
                    placeholder="Enter Phone Number"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleChange}
                  />

                  {/* <button className="ml-2 px-4 py-2 border-2 border-[rgba(74,61,229,0.25)] text-[rgba(74,61,229,0.25)] font-bold rounded   focus:outline-none focus:shadow-outline">
                    Verify
                  </button> */}
                </div>
                {errors.phone_number && (
                  <p className="text-red-500 text-xs mt-2">{errors.phone_number}</p>
                )}
              </div>

              <div className="mb-4 mt-[30px]">
                <label className="block text-[#000000]  text-[14px] leading-[16px] font-chivo  font-medium mb-2" htmlFor="reference">
                  Reference Name
                </label>
                <input
                  className={`shadow h-[48px] text-sm appearance-none border-[1px] border-b-4  rounded w-full py-2 px-3 text-gray-700 focus:outline-none ${errors.reference_name ? "border-red-500" : "border-black"
                    }`}
                  id="reference_name"
                  type="text"
                  placeholder="Enter Reference Name"
                  name="reference_name"
                  value={formData.reference_name}
                  onChange={handleChange}
                />
                {errors.reference_name && (
                  <p className="text-red-500 text-xs mt-1">{errors.reference_name}</p>
                )}
              </div>

              <div className="mb-4 mt-[30px]">
                <label className="block text-[#000000] text-[14px] leading-[16px] font-chivo  font-medium mb-2">
                  Gender
                </label>
                <div className="flex gap-[53px] items-center">
                  <div className="flex  items-center">
                    <input
                      id="male"
                      type="radio"
                      name="gender"
                      value="Male"
                      className="mr-2 h-[24px] w-[24px]"
                      checked={formData.gender === "Male"}
                      onChange={handleChange}
                    />
                    <label htmlFor="male" className="mr-4 font-chivo  font-medium text-[14px] leading-[16px]">Male</label>
                  </div>
                  <div className="flex  items-center">
                    <input
                      id="female"
                      type="radio"
                      name="gender"
                      value="Female"
                      className="mr-2 h-[24px] w-[24px]"
                      checked={formData.gender === "Female"}
                      onChange={handleChange}
                    />
                    <label htmlFor="female" className="font-chivo  font-medium text-[14px] leading-[16px]">Female</label>
                  </div>
                </div>
                {errors.gender && <p className="text-red-500 text-xs mt-1">{errors.gender}</p>}

              </div>

              <div className="mb-4 mt-[30px]">
                <label className="block text-[#000000] text-[14px] leading-[16px] font-chivo  font-medium mb-2" htmlFor="resume">
                  Attach Resume
                </label>
                <input
                  className={`shadow h-[48px] text-sm appearance-none border-[1px] border-b-4  rounded w-full py-2 px-3 text-gray-700 focus:outline-none ${errors.resume ? "border-red-500" : "border-black"
                    }`} id="resume"
                  type="file"
                  name="resume"
                  onChange={handleChange}
                />
                {errors.resume && <p className="text-red-500 text-xs mt-1">{errors.resume}</p>}
              </div>

              <div className="mb-4 mt-[30px]">
                <label className="block text-[#000000] text-[14px] leading-[16px] font-chivo  font-medium mb-2" htmlFor="jobLocation">
                  Job Location
                </label>
                <input
                  className={`shadow h-[48px] text-sm appearance-none border-[1px] border-b-4  rounded w-full py-2 px-3 text-gray-700 focus:outline-none ${errors.job_location ? "border-red-500" : "border-black"
                    }`}
                  id="job_location"
                  type="text"
                  placeholder="Enter Job Location"
                  name="job_location"
                  value={formData.job_location}
                  onChange={handleChange}
                />
                {errors.job_location && (
                  <p className="text-red-500 text-xs mt-1">{errors.job_location}</p>
                )}
              </div>
              <div className="mb-4 flex  items-center mt-[30px]">
                <input
                  id="agree"
                  type="checkbox"
                  className=" mr-2 h-[24px] w-[24px] leading-tight"
                  name="agree"
                  checked={formData.agree}
                  onChange={handleChange}
                />
                <label className="text-[#000000] font-chivo  font-normal text-[14px] leading-[16px] " htmlFor="agree">
                  I hereby accept all the Terms and Conditions as well as the Privacy Policy of the company.              </label>
              </div>
              {errors.agree && (
                  <p className="text-red-500 text-xs mt-1">{errors.agree}</p>
                )}
              <button
                className={`bg-[#469FD4] h-[48px] hover:bg-[#469FD4] xl:mt-[50px] ipad-pro:mt-[50px] md:mt-[50px] mt-[25px] border-[0.8px] border-b-4 border-blue-700 rounded-tl rounded-tr rounded-bl text-white font-bold  w-full py-2 px-3 rounded focus:outline-none focus:shadow-outline flex justify-center items-center ${formLoading ? 'cursor-wait opacity-50' : 'cursor-pointer'}`}
                type="submit"
                disabled={formLoading}

              >
                  {formLoading ? (
                    <div className="w-5 h-5 border-4 border-t-4 border-white rounded-full animate-spin"></div> // Spinner
                  ) : (
                    "Submit"
                  )}
              </button>
            </div>
          </form>

        </div>
      </section>
      {showThankYouPopup && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-[95%] md:w-[50%] text-center items-center flex flex-col">
            <img width="800" height="600" sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"
              src="https://webkorps-app.s3.us-east-1.amazonaws.com/thankyouImage.webp"
              alt="Thank You"
              className="w-[100px] h-[100px] mb-5"
            />
            <h2 className="text-xl font-bold mb-4">Thank You!</h2>
            <p className="text-gray-700 mb-4">Your application has been submitted successfully.</p>
            <button
              className="bg-[#469FD4] text-white px-4 py-2 rounded-md"
              onClick={() => setShowThankYouPopup(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}
